import { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state";
import { LoanFigureComponent } from "../loan-figure-component";
import theme from "../../theme";
import { moneyFormat } from "../../simulations/utils";
import { AddedLoanAmount } from "./AddedLoanAmount";
import { YearlyLoanAmount } from "../../types";
import { loanAdditionByStep } from "./step-logic";

const Style = styled.div`
  .loan-grid {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 1em;
    padding-bottom: 1em;

    .loan-column {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 16px;
      border-radius: 6px;

      .loan-figure-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
    }

    .purple-border {
      border: 1px solid ${theme.colors.graphColors.eggplantPurple};
    }

    .green-border {
      border: 1px solid ${theme.colors.graphColors.mediumGreen};
    }

    .blue-border {
      border: 1px solid ${theme.colors.graphColors.indigo};
    }
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      gap: 10px;
      b {
        font-size: 16px;
      }
      .loan-column {
        width: 100%;
        padding: 0;
      }
      .blue-border {
        padding: 8px;
      }
      .green-border {
        padding: 8px;
      }
      .purple-border {
        padding: 8px;
      }
    }
  }
`;

interface LoanTrackerComponentProps {}

export const LoanTrackerComponent: FC<LoanTrackerComponentProps> = () => {
  const {
    currentYear,
    loanAccumulationStepIndex,
    loanBalanceSoFar,
    currentFinancingOptionsResult,
  } = useSelector((state: RootState) => state.financingOptions);

  const currentYearLoanBreakdown =
    currentFinancingOptionsResult &&
    currentFinancingOptionsResult.annualLoanAccumulationData.find(
      (yearLoanObject: YearlyLoanAmount) => yearLoanObject.year === currentYear,
    );

  if (!currentYearLoanBreakdown) return null;

  const loanAdditionObject = loanAdditionByStep(
    (loanAccumulationStepIndex - 3) % 7,
    currentYearLoanBreakdown,
  );

  return (
    <Style>
      <h3 className="red">YEAR {currentYear}</h3>
      <div className="loan-grid">
        <div
          className={`loan-column ${
            [
              "federalSubsidizedPrincipalAmt",
              "federalSubsidizedInterestAmt",
            ].includes(loanAdditionObject.key) && "purple-border"
          }`}
        >
          <b className="purple-text">Federal subsidized loans</b>
          <div className="loan-figure-container">
            <LoanFigureComponent
              label="LOAN BALANCE"
              value={"$" + moneyFormat(loanBalanceSoFar.federalSubsidized)}
              textColor={theme.colors.graphColors.eggplantPurple}
            />
            {[
              "federalSubsidizedPrincipalAmt",
              "federalSubsidizedInterestAmt",
            ].includes(loanAdditionObject.key) && (
              <AddedLoanAmount
                amount={loanAdditionObject.value}
                label={loanAdditionObject.label}
                bgColor={theme.colors.graphColors.eggplantPurple}
              />
            )}
          </div>
        </div>
        <div
          className={`loan-column ${
            [
              "federalUnsubsidizedPrincipalAmt",
              "federalUnsubsidizedInterestAmt",
            ].includes(loanAdditionObject.key) && "green-border"
          }`}
        >
          <b className="green-text">Federal unsubsidized loans</b>
          <div className="loan-figure-container">
            <LoanFigureComponent
              label="LOAN BALANCE"
              value={"$" + moneyFormat(loanBalanceSoFar.federalUnsubsidized)}
              textColor={theme.colors.graphColors.mediumGreen}
            />
            {[
              "federalUnsubsidizedPrincipalAmt",
              "federalUnsubsidizedInterestAmt",
            ].includes(loanAdditionObject.key) && (
              <AddedLoanAmount
                amount={loanAdditionObject.value}
                label={loanAdditionObject.label}
                bgColor={theme.colors.graphColors.mediumGreen}
              />
            )}
          </div>
        </div>
        <div
          className={`loan-column ${
            ["privatePrincipalAmt", "privateInterestAmt"].includes(
              loanAdditionObject.key,
            ) && "blue-border"
          }`}
        >
          <b className="blue-text">Private loans</b>
          <div className="loan-figure-container">
            <LoanFigureComponent
              label="LOAN BALANCE"
              value={"$" + moneyFormat(loanBalanceSoFar.private)}
              textColor={theme.colors.graphColors.indigo}
            />
            {["privatePrincipalAmt", "privateInterestAmt"].includes(
              loanAdditionObject.key,
            ) && (
              <AddedLoanAmount
                amount={loanAdditionObject.value}
                label={loanAdditionObject.label}
                bgColor={theme.colors.graphColors.indigo}
              />
            )}
          </div>
        </div>
      </div>
    </Style>
  );
};
