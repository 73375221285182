import Tooltip from "@mui/material/Tooltip";
import { FC, ReactNode } from "react";
import QuestionMark from "../assets/icons/question-mark-circle-orange.svg";
import { makeStyles } from "@mui/styles";

interface TooltipProps {
  text: string | ReactNode;
  anchorName: string;
  anchorText?: string;
}

const useStyles = makeStyles({
  tooltipContent: {
    fontFamily: "Karla",
    fontSize: "14px",
    maxWidth: "100%",
    lineHeight: "150%",
    marginBottom: "2px !important",
  },
});

const MUIToolTip: FC<TooltipProps> = ({ text, anchorName, anchorText }) => {
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltipContent }}
      title={text}
      placement="top"
    >
      {anchorText ? (
        <span className={`help-link ${anchorName}`}>{anchorText}</span>
      ) : (
        <img className={anchorName} src={QuestionMark} alt="Question mark" />
      )}
    </Tooltip>
  );
};

export default MUIToolTip;
