import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../state";
import theme from "../theme";
import { FC } from "react";
import { moneyFormat } from "../simulations/utils";
import { ResponsivePie } from "@nivo/pie";

const Style = styled.div`
  .annual-title {
    border: 2px ${theme.colors.secondary} solid;
    text-align: center;
    max-width: fit-content;
    border-radius: 5px;
    padding: 12px;
  }

  .annual-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .pie-chart {
    height: 280px;
    width: 100%;
  }

  .loan-summary {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  .plans {
    display: flex;
    gap: 200px;
    align-items: flex-start;
  }

  .outline-item {
    border: 2px ${theme.colors.secondary} solid;
    text-align: left;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 130%;
  }
`;

interface StudentLoanSummaryProps {
  selectedCollegeId: string;
}

const StudentLoanSummary: FC<StudentLoanSummaryProps> = ({
  selectedCollegeId,
}) => {
  const financingOptionResults = useSelector(
    (state: RootState) => state.financingOptions.results,
  );
  const selectedCollegeFinancingResult = financingOptionResults.find(
    (school) => school.collegeId === selectedCollegeId,
  );

  return (
    <Style>
      <div className="annual-title-container">
        <div className="summary-card-title annual-title">
          Total Estimated Loan Amount: $
          {moneyFormat(
            selectedCollegeFinancingResult
              ? selectedCollegeFinancingResult.totalLoanAmount
              : 0,
          )}
        </div>
      </div>
      {selectedCollegeFinancingResult &&
        selectedCollegeFinancingResult.totalLoanAmount !== 0 && (
          <>
            <p className="loan-summary">
              We recommend you borrow{" "}
              <b>
                $
                {moneyFormat(
                  selectedCollegeFinancingResult
                    ? selectedCollegeFinancingResult.totalSubsidized +
                        selectedCollegeFinancingResult.totalUnsubsidized
                    : 0,
                )}
              </b>{" "}
              in federal loans and the remaining{" "}
              <b>
                $
                {moneyFormat(
                  selectedCollegeFinancingResult
                    ? selectedCollegeFinancingResult.totalPrivate
                    : 0,
                )}
              </b>{" "}
              in private loans.{" "}
            </p>
            <div className="pie-chart">
              <ResponsivePie
                valueFormat=" ^-$,"
                data={[
                  {
                    id: "subsidizedLoans",
                    label: "Federal Subsidized loans",
                    value: `${
                      selectedCollegeFinancingResult
                        ? selectedCollegeFinancingResult.totalSubsidized
                        : 0
                    }`,
                  },
                  {
                    id: "unsubsidizedLoans",
                    label: "Federal Unsubsidized loans",
                    value: selectedCollegeFinancingResult
                      ? selectedCollegeFinancingResult.totalUnsubsidized
                      : 0,
                  },
                  {
                    id: "privateLoans",
                    label: "Private loans",
                    value: selectedCollegeFinancingResult
                      ? selectedCollegeFinancingResult.totalPrivate
                      : 0,
                  },
                ]}
                margin={{ top: 20, right: 0, bottom: 0, left: 0 }}
                enableArcLinkLabels={true}
                arcLinkLabel={(d) => `${d.label}`}
                isInteractive={false}
                legends={[]}
                theme={{
                  fontSize: 18,
                  legends: { text: { fontSize: 12 } },
                }}
              />
            </div>

            <div className="plans">
              <div className="plan-section">
                <p className="summary-card-title">
                  Standard Loan Repayment Plan:
                </p>
                <div className="stats">
                  <div className="outline-item">
                    Monthly payment:{" "}
                    <b>
                      $
                      {moneyFormat(
                        selectedCollegeFinancingResult
                          ? selectedCollegeFinancingResult.financingInfoStandard
                              .monthlyPayment
                          : 0,
                      )}
                      /month
                    </b>
                  </div>
                  <div className="outline-item">
                    Loan repayment period:{" "}
                    <b>
                      {moneyFormat(
                        selectedCollegeFinancingResult
                          ? selectedCollegeFinancingResult.financingInfoStandard
                              .loanRepaymentTerms
                          : 0,
                      )}{" "}
                      years
                    </b>
                  </div>
                </div>
              </div>
              <div className="plan-section">
                <p className="summary-card-title">
                  Income-Driven Repayment Plan:
                </p>
                <div className="stats">
                  <div className="outline-item">
                    Monthly payment: <b>Changes based on your income</b>
                  </div>
                  <div className="outline-item">
                    Loan repayment period: <b>20 years</b>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
    </Style>
  );
};

export default StudentLoanSummary;
