export function removeCommas(text: string): string {
  return text.length === 0
    ? "0"
    : parseInt(text.replaceAll(",", "")).toString();
}

export const scrollToElement = (id: string) => {
  const headingToScrollTo = document.getElementById(id);
  if (headingToScrollTo) {
    headingToScrollTo.scrollIntoView();
  }
};

export const roundToNearest = (roundingValue: number, threshold: number) => {
  return Math.round(roundingValue / threshold) * threshold;
};
