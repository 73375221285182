import SummaryHeader from "./SummaryHeader";
import SummaryContent from "./SummaryContent";
import styled from "styled-components";

const Style = styled.div`
  .summary {
    text-align: center;
  }
`;
function DownloadSummary() {
  return (
    <Style>
      <h1 className="summary">Finiverse Summary</h1>
      <SummaryHeader />
      <SummaryContent />
    </Style>
  );
}

export default DownloadSummary;
