import styled from "styled-components";
import theme from "../theme";
import { useState } from "react";
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { RootState } from "../state";
import { useDispatch, useSelector } from "react-redux";
import US_STATES from "./US_STATES";
import { Controller, useForm } from "react-hook-form";
import {
  currencyRegex,
  integerGreaterThan1,
  invalidInputMessage,
  requiredInputMessage,
} from "../constants";
import { removeCommas } from "../utils";
import SearchDropdown from "../components/SearchDropdown";
import { Divider } from "@mui/material";
import { setShowNetPriceResults } from "./college-costs-slice";
import { updateFinancingOptionsBySchoolId } from "../student-loans/financing-options-slice";

const Style = styled.div`
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .option-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    .option {
      gap: 8px;
    }
  }

  .label {
    width: 80%;
  }

  .help-icon {
    margin-left: 5px;
    width: 0.85rem;
    height: 0.85rem;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }

  .error {
    color: ${theme.colors.graphColors.redOrange};
    font-weight: 700px;
    padding-bottom: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    h3 {
      margin-bottom: 0px;
    }
  }

  .description {
    display: flex;
    justify-content: center;
  }
  .muiDivider {
    background-color: ${theme.colors.primary};
    width: 80%;
    height: 1.5px;
    border-bottom-width: none;
  }

  .resultText {
    color: ${theme.colors.secondary};
    font-weight: bold;
  }

  .dropdown-container {
    display: flex;

    .dropdown-label {
      flex-basis: 1000px;
      max-width: 50%;
    }
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .dropdown-container {
      flex-direction: column;

      .dropdown-label {
        flex-basis: 0px;
        min-width: 100%;
      }
    }
  }
`;

const selectCollegeCostsState = (state: RootState) => state.collegeCosts;

interface IFormInputs {
  householdSize: string;
  parentIncome: string;
  studentIncome: string;
  studentScholarshipsOrGrants: string;
  parentAssets?: string;
  studentAssets?: string;
}

interface CollegeCostsSAIProps {
  showOverlay: boolean;
  hideOverlay: () => void;
  calculateCosts: () => void;
}

const CollegeCostsSAI: React.FC<CollegeCostsSAIProps> = ({ hideOverlay }) => {
  const dispatch = useDispatch();
  const { stateOfResidence } = useSelector(
    selectCollegeCostsState,
  ).selectedOptions;
  const isSAICalculated = useSelector(
    selectCollegeCostsState,
  ).showNetPriceResults;
  const { results } = useSelector((state: RootState) => state.collegeCosts);

  const [saiAmount, setSAIAmount] = useState(0);
  const [pellGrantAmount, setPellGrantAmount] = useState(0);

  const [SAIInformation, setSAIInformation] = useState({
    stateOfResidency: US_STATES[stateOfResidence],
    householdSize: "",
    parentMaritalStatus: "",
    parentIncome: "",
    studentIncome: "",
    studentScholarshipsOrGrants: "",
    parentAssets: "",
    studentAssets: "",
  });

  // If SAI calculation has been performed
  // const [isSAICalculated, setIsSAICalculated] = useState(false);

  // Handle input change if "No" is selected
  const handleSAIInformationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSAIInformation({
      ...SAIInformation,
      [event.target.name]: event.target.value,
    });
    // When SAI information is changed, SAI calculation must be performed again
    dispatch(setShowNetPriceResults(false));
  };

  const handleSAICalculate = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/sai-calculation?` +
        new URLSearchParams([
          ["parentAGI", removeCommas(SAIInformation.parentIncome)],
          ["studentAGI", removeCommas(SAIInformation.studentIncome)],
          ["familySize", SAIInformation.householdSize],
          [
            "isSingleParent",
            (SAIInformation.parentMaritalStatus === "Single").toString(),
          ],
          ["parentAssets", removeCommas(SAIInformation.parentAssets)],
          ["studentAssets", removeCommas(SAIInformation.studentAssets)],
          [
            "scholarshipGrant",
            removeCommas(SAIInformation.studentScholarshipsOrGrants),
          ],
          ["olderParentAge", "50"],
        ]),
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => response.json())
      .then((responseJSON) => {
        dispatch(setShowNetPriceResults(true));
        setSAIAmount(responseJSON.sai);
        setPellGrantAmount(responseJSON.pellGrant);
      });
  };

  const handleSAISubmit = () => {
    results.forEach((result) => {
      dispatch(
        updateFinancingOptionsBySchoolId({
          collegeId: result.collegeId,
          dataToUpdate: {
            studentAidIndexAmount: saiAmount.toString(),
            pellGrantAmount: pellGrantAmount.toString(),
          },
        }),
      );
    });

    hideOverlay();
  };

  // Input validation
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {},
  });

  return (
    <Style>
      <h2 className="title">Student Aid Index Calculator</h2>
      <div className="options">
        <div className="description">
          <p className="label">
            The <b>Student Aid Index (SAI)</b> is a calculation of the amount of
            need-based financial aid that a student is eligible to receive. It
            will be used to calculate aid starting in the{" "}
            <b>2024-2025 academic year</b>.
          </p>
        </div>
        <form onSubmit={handleSubmit(handleSAICalculate)} className="form">
          <h3 className="label">Basic information (required)</h3>
          <div className="option-container">
            <div className="dropdown-container">
              <p className="dropdown-label">State of Residency</p>
              <SearchDropdown
                fieldTitle=""
                placeholderValue="State"
                fieldValue={SAIInformation.stateOfResidency}
                dropdownOptions={Object.values(US_STATES)}
                handleChange={(e, v) => {
                  setSAIInformation({
                    ...SAIInformation,
                    stateOfResidency: v.label,
                  });
                }}
              />
            </div>

            <div className="dropdown-container">
              <p className="dropdown-label">Parent/Guardian's marital Status</p>
              <SearchDropdown
                fieldTitle=""
                placeholderValue="Status"
                fieldValue={SAIInformation.parentMaritalStatus}
                dropdownOptions={["Single", "Married"]}
                handleChange={(e, v) => {
                  setSAIInformation({
                    ...SAIInformation,
                    parentMaritalStatus: v.label,
                  });
                }}
              />
            </div>

            <Controller
              name="householdSize"
              control={control}
              rules={{
                required: requiredInputMessage,
                pattern: {
                  value: integerGreaterThan1,
                  message: "*Please enter a valid integer greater than 1",
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Size of Household"
                  name="householdSize"
                  value={SAIInformation.householdSize}
                  helperText={errors.householdSize?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />

            <Controller
              name="parentIncome"
              control={control}
              rules={{
                required: requiredInputMessage,
                pattern: {
                  value: currencyRegex,
                  message: invalidInputMessage,
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Parent's Income"
                  name="parentIncome"
                  value={SAIInformation.parentIncome}
                  helperText={errors.parentIncome?.message}
                  adornment="$"
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />

            <Controller
              name="studentIncome"
              control={control}
              rules={{
                required: requiredInputMessage,
                pattern: {
                  value: currencyRegex,
                  message: invalidInputMessage,
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Student's Income"
                  name="studentIncome"
                  value={SAIInformation.studentIncome}
                  helperText={errors.studentIncome?.message}
                  adornment="$"
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />

            <Controller
              name="studentScholarshipsOrGrants"
              control={control}
              rules={{
                required: requiredInputMessage,
                pattern: {
                  value: currencyRegex,
                  message: invalidInputMessage,
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Student's Scholarships & Grants"
                  name="studentScholarshipsOrGrants"
                  value={SAIInformation.studentScholarshipsOrGrants}
                  helperText={errors.studentScholarshipsOrGrants?.message}
                  adornment="$"
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />
          </div>
          <h3 className="label">Assets information (optional)</h3>
          <div className="option-container">
            <Controller
              name="parentAssets"
              control={control}
              rules={{
                required: false,
                pattern: {
                  value: currencyRegex,
                  message: invalidInputMessage,
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Parent's Total Assets"
                  name="parentAssets"
                  value={SAIInformation.parentAssets}
                  helperText={errors.parentAssets?.message}
                  adornment="$"
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />
            <Controller
              name="studentAssets"
              control={control}
              rules={{
                required: false,
                pattern: {
                  value: currencyRegex,
                  message: invalidInputMessage,
                },
              }}
              render={({ field }) => (
                <TextInput
                  field={field}
                  label="Student's Total Assets"
                  name="studentAssets"
                  value={SAIInformation.studentAssets}
                  helperText={errors.studentAssets?.message}
                  adornment="$"
                  onChange={(e) => {
                    field.onChange(e);
                    handleSAIInformationChange(e);
                  }}
                />
              )}
            />
          </div>
          {isSAICalculated && (
            <>
              <Divider className="muiDivider" variant="middle" />
              <div className="option-container resultText">
                <TextInput
                  name="SAIOutput"
                  label="Student Aid Index (SAI)"
                  helperText={errors.studentAssets?.message}
                  value={saiAmount.toString()}
                  adornment="$"
                  readOnly={true}
                  onChange={() => {}}
                />
                <TextInput
                  name="PellGrantOutput"
                  readOnly={true}
                  label="Pell Grant Eligibility"
                  value={pellGrantAmount.toString()}
                  helperText={errors.studentAssets?.message}
                  adornment="$"
                  onChange={() => {}}
                />
              </div>
            </>
          )}
          <div className="button-container">
            {isSAICalculated ? (
              <Button buttonText="Submit" handleOnClick={handleSAISubmit} />
            ) : (
              <div className="option">
                <Button
                  buttonText="Calculate"
                  bgColor={theme.colors.secondary}
                  borderColor={theme.colors.secondary}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </Style>
  );
};

export default CollegeCostsSAI;
