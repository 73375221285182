import Email from "../assets/icons/email-icon.svg";
import styled from "styled-components";
import theme from "../theme";
import InstagramIcon from "../assets/icons/instagram_simple.svg";

const Style = styled.section`
  .contact-section-description {
    margin-top: 10px;
    font-size: 22px;
    line-height: 110%;
    margin-bottom: 36px;
  }
  .contact-section-title {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: 40px;
  }
  .contact-section {
    display: flex;
  }
  .contact-section-right {
    flex: 2;
  }
  .contact-section-icon {
    margin-right: 30px;
    width: 54px;
  }

  .social-media-link {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .contact-section-container {
      padding: 0 50px;
    }

    .contact-section-description {
      margin-bottom: 24px;
    }

    .contact-section-title {
      font-size: 20px;
    }

    .contact-section-icon {
      width: 40px;
      margin: 0 30px 0 0;
    }
  }
`;

function ContactUs() {
  return (
    <Style>
      <div className="contact-section-container">
        <p className="contact-section-description">
          Please reach out to us if you have any questions about the project or
          if you are interested in joining.
        </p>
        <div className="contact-section">
          <img className="contact-section-icon" src={Email} alt="Email icon" />
          <div className="contact-section-right">
            <h2 className="contact-section-title">Email us</h2>
            <p className="contact-section-description">contact@finiverse.org</p>
          </div>
        </div>
        <div className="contact-section">
          <img
            className="contact-section-icon"
            src={InstagramIcon}
            alt="Instagram icon"
          />
          <div className="contact-section-right">
            <h2 className="contact-section-title">Follow us on Instagram</h2>
            <a
              className="social-media-link"
              href="https://www.instagram.com/finiversewharton/"
            >
              <p className="contact-section-description">@finiversewharton</p>
            </a>
          </div>
        </div>
      </div>
    </Style>
  );
}

export default ContactUs;
