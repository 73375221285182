import styled from "styled-components";
import { Button } from "./Button";
import rightArrow from "../assets/icons/arrow-right-blue.svg";
import leftArrow from "../assets/icons/arrow-left-blue.svg";
import { useNavigate } from "react-router-dom";
import theme from "../theme";

interface NavButtonFooterProps {
  nextPageLink?: string;
  prevPageLink?: string;
  nextPageButtonText?: string;
  prevPageButtonText?: string;
  nextPageIcon?: string;
  prevPageIcon?: string;
  actionOnClick?: () => void; // this is only used on the Simulation page
  orangeNextButton?: boolean; // this is only true on the Simulation page
}

const Style = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  @media (max-width: ${theme.breakpoints.xlarge}px) {
    gap: 30px;
  }
`;

export const NavButtonFooter: React.FC<NavButtonFooterProps> = ({
  nextPageButtonText,
  nextPageLink,
  prevPageButtonText,
  prevPageLink,
  nextPageIcon = rightArrow,
  prevPageIcon = leftArrow,
  orangeNextButton = false,
  actionOnClick,
}) => {
  let navigate = useNavigate();

  return (
    <Style>
      {prevPageButtonText && prevPageLink && (
        <Button
          buttonText={prevPageButtonText}
          handleOnClick={() => navigate(prevPageLink)}
          buttonLeftIcon={prevPageIcon}
          bgColor={theme.colors.neutrals.white}
          borderColor={theme.colors.primary}
          textColor={theme.colors.primary}
        />
      )}
      {nextPageButtonText && nextPageLink && (
        <Button
          buttonText={nextPageButtonText}
          handleOnClick={() =>
            orangeNextButton && actionOnClick
              ? actionOnClick()
              : navigate(nextPageLink)
          }
          buttonRightIcon={nextPageIcon}
          bgColor={
            orangeNextButton
              ? theme.colors.secondary
              : theme.colors.neutrals.white
          }
          borderColor={
            orangeNextButton ? theme.colors.secondary : theme.colors.primary
          }
          textColor={
            orangeNextButton
              ? theme.colors.neutrals.white
              : theme.colors.primary
          }
        />
      )}
    </Style>
  );
};

export default NavButtonFooter;
