import { Autocomplete, FilterOptionsState, TextField } from "@mui/material";
import theme from "../theme";

interface SearchDropdownProps {
  fieldTitle: string;
  fieldValue: string;
  placeholderValue?: string;
  dropdownOptions: string[];
  handleChange: (e: any, value: any) => void;
}

export const SearchDropdown: React.FC<SearchDropdownProps> = ({
  fieldTitle,
  fieldValue,
  dropdownOptions,
  placeholderValue = fieldTitle,
  handleChange,
}) => {
  const reformattedOptions = dropdownOptions.map((dropdownOption) => ({
    label: dropdownOption,
  }));
  const handleFilterOptions = (
    options: { label: string }[],
    state: FilterOptionsState<{ label: string }>,
  ) => {
    let filteredOptions: { label: string }[] = [];

    // deduplicate filtered options
    // TODO: deduplicate initial options in backend instead
    let filteredSet = new Set();
    options.forEach((option) => {
      if (
        !filteredSet.has(option.label) &&
        option.label.toLowerCase().includes(state.inputValue.toLowerCase())
      ) {
        filteredSet.add(option.label);
        filteredOptions.push(option);
      }
    });
    return filteredOptions;
  };
  return (
    // <Style>
    <Autocomplete
      disablePortal
      filterOptions={handleFilterOptions}
      options={reformattedOptions}
      fullWidth
      filterSelectedOptions={false}
      className="search-dropdown"
      onChange={handleChange}
      isOptionEqualToValue={(option, value) => {
        return option.label === value.label;
      }}
      value={{ label: fieldValue }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          sx={{ background: theme.colors.neutrals.white }}
          label={fieldTitle}
          value={fieldValue}
          placeholder={placeholderValue}
        />
      )}
    />
    // </Style>
  );
};

export default SearchDropdown;
