import { createSlice } from "@reduxjs/toolkit";
import { NavigationState } from "../types";

const initialState: NavigationState = {
  showSummaryButton: false,
  activePages: [],
};

const slice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setShowSummaryButton: (state, action) => {
      state.showSummaryButton = action.payload;
    },
    addActivePage: (state, action) => {
      state.activePages = [...state.activePages, action.payload];
    },
  },
});

export const { setShowSummaryButton, addActivePage } = slice.actions;

export default slice.reducer;
