import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import slider_icon from "../assets/icons/slider.svg";
import theme from "../theme";
import { moneyFormat } from "../simulations/utils";
import { useDispatch } from "react-redux";
import { updateLoanAccumulationStepIndex } from "../student-loans/financing-options-slice";

const Style = styled.div`
  .slider {
    width: 100%;
    height: 20px;
    display: flex;
    border-radius: 6px;
    margin: 0 auto;
    position: relative;
  }

  .box {
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .box:nth-of-type(1) {
    border-radius: 6px 0 0 6px;
  }

  .box:nth-last-of-type(1) {
    border-radius: 0 6px 6px 0;
  }

  .thumb {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    transform: translate(-50%, -21%); //idk what value
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 0;
    cursor: pointer;
    user-select: none;
    touch-action: none;
  }

  .box > .tooltip {
    display: none;
    position: absolute;
    width: 300px;
    height: 60px;
    margin-top: 40px;
    background-color: #ffffff;
    padding: 2px 7px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    p {
      margin: 0;
    }
  }

  .box > .tooltip::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: -15px;
    width: 0;
    height: 0;
    border-bottom: 1rem solid ${theme.colors.neutrals.white};
    border-right: 1rem solid transparent;
    border-left: 1rem solid transparent;
    transform: translateX(-50%);
  }
`;

interface SliderProps {
  data: {
    name: string;
    defaultValue: number;
    bgColor: string;
  }[];
  onChange?: (values: { name: string; value: number }[]) => void;
  increment: number;
  lastReadOnly?: boolean;
}

/**
 *
 * A multi-slider component that allows the user to adjust the values of some given data.
 *
 * @component
 * @param {object[]} props.data - The data for the sliders
 *   @property {string} name - The name of the data
 *   @property {number} defaultValue - The default value of the data
 *   @property {string} bgColor - The background color of the slider bar
 * @param {function} props.onChange - The callback function to call when the slider changes
 * @param {number} props.increment - The increment value
 * @param {boolean} props.lastReadOnly - If the last slider should be read only
 */

export const Slider = ({
  data,
  increment,
  onChange,
  lastReadOnly = false,
}: SliderProps) => {
  const dispatch = useDispatch();
  // Calculate the total value of the sliders
  const totalValue = useMemo(() => {
    return data.reduce(
      (sum, currentValue) => sum + currentValue.defaultValue,
      0,
    );
  }, [data]);

  // The relative percentage increment
  const prctInc = increment / totalValue;

  const calculateThumbPositions = useCallback(() => {
    return data
      .slice(0, data.length - 1)
      .map(
        (_, index) =>
          data
            .slice(0, index + 1)
            .reduce((sum, currentValue) => sum + currentValue.defaultValue, 0) /
          totalValue,
      );
  }, [data, totalValue]);
  // Set the initial thumb positions based on the default values
  const [thumbPositions, setThumbPositions] = useState(
    calculateThumbPositions(),
  );

  const calculateWidthSize = (positions: typeof thumbPositions) =>
    positions
      .map((position, index) => position - (thumbPositions[index - 1] || 0))
      .concat(1 - thumbPositions[thumbPositions.length - 1]);

  const widthSizes = calculateWidthSize(thumbPositions);
  const calculatedValues = widthSizes.map((size) =>
    Math.round(size * totalValue),
  );

  const [isDragging, setIsDragging] = useState(false);
  const [currentDrag, setCurrentDrag] = useState(-1);
  const [hoveredBox, setHoveredBox] = useState(-1);

  const updateThumbPosition = (index: number, newValue: number) => {
    setThumbPositions((prevPositions) => {
      const newPositions = [...prevPositions];
      newPositions[index] = newValue;
      return newPositions;
    });
  };
  const updatePosition = useCallback(
    (e: MouseEvent | TouchEvent | KeyboardEvent, key: number) => {
      if (key < 0) return;

      let roundedPercentage = thumbPositions[key];

      // Calculate position based on mouse, touch, or keyboard event
      if (!(e instanceof KeyboardEvent)) {
        let clientX = 0;
        if (e instanceof MouseEvent) {
          clientX = e.clientX;
        } else if (e instanceof TouchEvent) {
          clientX = e.touches[0].clientX;
        }

        const slider = document.querySelector(".slider") as HTMLDivElement;

        const sliderRect = slider.getBoundingClientRect();
        const relativeX = clientX - sliderRect.left;
        const sliderWidth = sliderRect.width;

        const percentage = relativeX / sliderWidth;

        roundedPercentage = Math.round(percentage / prctInc) * prctInc;
      } else {
        if (e.key === "ArrowLeft") {
          roundedPercentage = thumbPositions[key] - prctInc;
        } else if (e.key === "ArrowRight") {
          roundedPercentage = thumbPositions[key] + prctInc;
        }
        roundedPercentage = Math.round(roundedPercentage / prctInc) * prctInc;
      }

      // If the thumb position hasn't changed, return
      if (thumbPositions[key] === roundedPercentage) return;

      const min =
        thumbPositions[key - 1] !== undefined ? thumbPositions[key - 1] : 0;
      const max =
        thumbPositions[key + 1] !== undefined ? thumbPositions[key + 1] : 1;

      // Ensure the thumb is within the bounds of the previous and next thumb
      if (roundedPercentage >= min && roundedPercentage <= max) {
        updateThumbPosition(key, roundedPercentage);
      } else if (roundedPercentage < min) {
        updateThumbPosition(key, min);
      } else if (roundedPercentage > max) {
        updateThumbPosition(key, max);
      }
    },
    [thumbPositions, prctInc],
  );

  const onInputBegin = (
    e: React.TouchEvent | React.MouseEvent | React.KeyboardEvent,
    key: number,
  ) => {
    if (lastReadOnly && key === thumbPositions.length - 1) return;

    setIsDragging(true);
    setCurrentDrag(key);
    updatePosition(e.nativeEvent, key);
  };

  const onInputMove = useCallback(
    (e: TouchEvent | MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        updatePosition(e, currentDrag);
      }
    },
    [isDragging, updatePosition, currentDrag],
  );

  const onInputEnd = useCallback(
    (e: TouchEvent | MouseEvent | KeyboardEvent) => {
      if (!isDragging) return;

      const newValues = data.map((value, index) => {
        return {
          name: value.name,
          value: calculatedValues[index],
        };
      });

      // Call the onChange callback if it exists
      if (onChange) onChange(newValues);

      setIsDragging(false);
      setCurrentDrag(-1);
      dispatch(updateLoanAccumulationStepIndex(0));
    },
    [isDragging, data, calculatedValues, onChange, dispatch],
  );

  const onBoxEnterHover = (e: React.MouseEvent, box: number) => {
    if (isDragging) return;
    setHoveredBox(box);
  };

  const onBoxLeaveHover = (e: React.MouseEvent) => {
    setHoveredBox(-1);
  };

  useEffect(() => {
    document.addEventListener("mouseup", onInputEnd);
    document.addEventListener("mousemove", onInputMove);

    document.addEventListener("touchend", onInputEnd);
    document.addEventListener("touchmove", onInputMove);

    // Clean up
    return () => {
      document.removeEventListener("mouseup", onInputEnd);
      document.removeEventListener("mousemove", onInputMove);

      document.removeEventListener("touchend", onInputEnd);
      document.removeEventListener("touchmove", onInputMove);
    };
  }, [isDragging, thumbPositions, onInputEnd, onInputMove]);

  // Update the thumb positions when the default values change (e.g. user edits the input)
  useEffect(() => {
    setThumbPositions(calculateThumbPositions());
  }, [calculateThumbPositions]);

  //sum of all data.defaultValue
  let sumValue = 0;
  data.forEach((data) => (sumValue += data.defaultValue));
  return (
    <Style>
      <div className="slider">
        {data.map((value, index) => (
          <div
            key={index}
            className="box"
            style={{
              width: widthSizes[index] * 100 + "%",
              backgroundColor: value.bgColor,
              border: hoveredBox === index ? "2px solid black" : "",
            }}
            aria-label="slider value bar"
            onMouseEnter={(e) => onBoxEnterHover(e, index)}
            onMouseLeave={(e) => onBoxLeaveHover(e)}
          >
            <div
              className="tooltip"
              role="tooltip"
              style={{
                display: hoveredBox === index ? "flex" : "none",
              }}
            >
              <p>{`${value.name}: $${moneyFormat(
                calculatedValues[index] ? calculatedValues[index] : 0,
              )}`}</p>
            </div>
          </div>
        ))}
        {sumValue !== 0 &&
          thumbPositions.map((position, index) => (
            <img
              key={index}
              tabIndex={0}
              className="thumb"
              // checking if value is 0
              src={slider_icon}
              draggable={false}
              style={{
                left: position * 100 + "%",
                zIndex:
                  lastReadOnly && thumbPositions.length - 1 === index
                    ? 0
                    : Math.round(widthSizes[index] * 100) + 1, // Order the thumbs based on the width size
              }}
              alt="slider thumb"
              role="slider"
              aria-valuenow={calculatedValues[index]}
              aria-valuemin={0}
              aria-valuemax={totalValue}
              aria-label="slider thumb"
              aria-readonly={
                lastReadOnly && thumbPositions.length - 1 === index
              }
              onMouseDown={(e) => onInputBegin(e, index)}
              onTouchStart={(e) => onInputBegin(e, index)}
              onKeyDown={(e) => onInputBegin(e, index)}
              onKeyUp={(e) => onInputEnd(e.nativeEvent)}
            />
          ))}
      </div>
    </Style>
  );
};

export default Slider;
