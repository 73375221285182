import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  results: {
    totalEarnings: [],
    totalExpenses: [],
    netAssets: [],
  },
};

const slice = createSlice({
  name: "multipleSimulation",
  initialState,
  reducers: {
    fetchMultipleSimulationResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const { fetchMultipleSimulationResults } = slice.actions;

export default slice.reducer;
