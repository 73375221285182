import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";

interface BannerProps {
  content: string | JSX.Element;
}

const Style = styled.div`
  padding: 8px 24px;
  background: ${theme.colors.pennBlue};
  color: ${theme.colors.neutrals.white};
`;

const Banner: FC<BannerProps> = ({ content }) => {
  return (
    <Style>
      <p>{content}</p>
    </Style>
  );
};

export default Banner;
