import styled from "styled-components";
import SearchDropdown from "./SearchDropdown";
import { JobEarningsResults } from "../types";
import theme from "../theme";
import MUIToolTip from "./MUIToolTip";

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  font-family: "Karla", sans-serif !important;
  @media (max-width: ${theme.breakpoints.xsmall}px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .row-label {
    font-size: 18px;
    font-weight: 700;
    width: 30%;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      font-size: 18px;
      width: 100%;
      line-height: 1.5em;
      text-align: left;
      margin-bottom: 3%;
    }
  }

  .search-dropdown {
    width: 65%;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      width: 100%;
      margin-bottom: 5%;
    }
  }

  .stats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: fit-content;

    .value {
      font-size: 24px;
      font-weight: 700;
      line-height: 110%;
    }

    .label {
      color: ${theme.colors.neutrals.darkGray};
      font-size: 16px;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }
`;

interface InputRowProps {
  rowLabel: string;
  fieldTitle: string;
  selectedValue: string;
  placeholderValue?: string;
  dropdownOptions: string[];
  handleChange: (e: any, value: any) => void;
  results?: JobEarningsResults;
}

export const InputRow: React.FC<InputRowProps> = ({
  rowLabel,
  fieldTitle,
  placeholderValue = fieldTitle,
  selectedValue,
  dropdownOptions,
  handleChange,
  results,
}) => {
  return (
    <Style>
      <div className="row-label">{rowLabel}</div>
      <SearchDropdown
        fieldTitle={fieldTitle}
        fieldValue={selectedValue}
        placeholderValue={placeholderValue}
        dropdownOptions={dropdownOptions}
        handleChange={handleChange}
      />
      {selectedValue && results && (
        <div className="stats">
          <div className="value">
            ${results.jobOptions[0]?.avgStartingSalary || "1000"}/month
          </div>
          <div className="label">
            Average starting salary
            <MUIToolTip
              text="The average amount you might be paid during your first year of working"
              anchorName="salary-anchor"
            />
          </div>
        </div>
      )}
    </Style>
  );
};

export default InputRow;
