import styled from "styled-components";
import theme from "../theme";
import { FC, ReactNode } from "react";

interface TextCalloutProps {
  text: ReactNode;
  bgColor?: string;
  borderColor?: string;
}

interface TextCalloutStyleProps {
  bgColor?: string;
  borderColor?: string;
}

const Style = styled.div<TextCalloutStyleProps>`
  background: ${(props) => props.bgColor || theme.colors.creamOrange};
  border: 2px solid ${(props) => props.borderColor || theme.colors.creamOrange};
  padding: 0.5em 1.5em;
  border-radius: 6px;
  text-align: left;
  @media (max-width: ${theme.breakpoints.xsmall}px) {
    padding: 0.5em 1em;
    p {
      font-size: 16px;
    }
    ul {
      padding-left: 0.8em;
    }
  }
`;

const TextCallout: FC<TextCalloutProps> = ({ text, bgColor, borderColor }) => {
  return (
    <Style bgColor={bgColor} borderColor={borderColor}>
      <p>{text}</p>
    </Style>
  );
};

export default TextCallout;
