import styled from "styled-components";
import { FC, ReactNode } from "react";
import theme from "../theme";

interface TimelineEventProps {
  message: ReactNode;
  icon: string;
}

const Style = styled.div`
  .timeline-event {
    border: 3px solid ${theme.colors.lightIndigo};
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    background: ${theme.colors.neutrals.white};
    min-width: 100px;
    min-height: 125px;
    max-width: 100%;

    @media (max-width: 1350px) {
      height: inherit;
      min-height: 0;
      min-width: 0px;
    }
  }
`;

const TimelineEvent: FC<TimelineEventProps> = ({ icon, message }) => {
  return (
    <Style>
      <div className="timeline-event">
        <img className="icon" src={icon} alt="" />
        <div className="text">{message}</div>
      </div>
    </Style>
  );
};

export default TimelineEvent;
