import styled from "styled-components";
import { Button } from "./Button";
import rightArrow from "../assets/icons/arrow-right-white.svg";
import leftArrow from "../assets/icons/arrow-left-white.svg";
import theme from "../theme";
import { updateLoanAccumulationStepIndex } from "../student-loans/financing-options-slice";
import { useDispatch } from "react-redux";

interface OrangeStepButtonProps {
  stepNumber: number;
  totalSteps: number;
  backButtonAction?: () => void;
  nextButtonAction?: () => void;
  restartButtonAction?: () => void;
  backButtonText?: string;
  nextButtonText?: string;
  restartButtonText?: string;
  nextButtonIcon?: string;
  backButtonIcon?: string;
  restartButtonIcon?: string;
}

const Style = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .back-next-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  @media (max-width: ${theme.breakpoints.small}px) {
    .restart-button {
      padding: 10px 8px;
    }
  }
`;

export const OrangeStepButtons: React.FC<OrangeStepButtonProps> = ({
  nextButtonIcon = rightArrow,
  backButtonIcon = leftArrow,
  restartButtonIcon,
  restartButtonAction,
  nextButtonAction,
  backButtonAction,
  nextButtonText = window.innerWidth < theme.breakpoints.xlarge ? "" : "Next",
  backButtonText = window.innerWidth < theme.breakpoints.xlarge ? "" : "Back",
  restartButtonText = "Restart",
  stepNumber,
  totalSteps,
}) => {
  const dispatch = useDispatch();
  return (
    <Style>
      <div className="back-next-buttons">
        {stepNumber > 0 && (
          <Button
            buttonText={backButtonText}
            handleOnClick={backButtonAction}
            buttonLeftIcon={backButtonIcon}
            bgColor={theme.colors.secondary}
            borderColor={theme.colors.secondary}
            textColor={theme.colors.neutrals.white}
          />
        )}
        {stepNumber <= totalSteps && (
          <Button
            buttonText={nextButtonText}
            handleOnClick={
              stepNumber === totalSteps
                ? () => dispatch(updateLoanAccumulationStepIndex(0))
                : nextButtonAction
            }
            buttonRightIcon={nextButtonIcon}
            bgColor={theme.colors.secondary}
            borderColor={theme.colors.secondary}
            textColor={theme.colors.neutrals.white}
          />
        )}
      </div>

      <Button
        className="restart-button"
        buttonText={restartButtonText}
        handleOnClick={restartButtonAction}
        buttonLeftIcon={restartButtonIcon}
        bgColor={theme.colors.neutrals.white}
        borderColor={theme.colors.secondary}
        textColor={theme.colors.secondary}
      />
    </Style>
  );
};

export default OrangeStepButtons;
