import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../theme";
import { Page } from "../types";
import { PageNames } from "../constants";
import { useSelector } from "react-redux";
import { RootState } from "../state";

interface Props {
  pageNumber: number;
  pages: Page[];
  allPagesActive?: boolean;
}

const Style = styled.nav`
  .navbar__tab {
    width: 33.33%;
    height: 5em;
    background-color: ${theme.colors.primary};
    list-style-type: none;
  }

  .front-arrow::after {
    content: "";
    display: block;
    position: relative;
    left: 100%;
    bottom: 100%;
    width: 0;
    height: 0;
    border-top: 2.5rem solid transparent;
    border-bottom: 2.5rem solid transparent;
    border-left: 1.5rem solid ${theme.colors.primary};
  }

  .tab-first {
    border-radius: 40px 0 0 0;
  }

  .tab-last {
    border-radius: 0 40px 0 0;
  }

  .incomplete {
    background-color: ${theme.colors.neutrals.lightGray};
  }

  .active {
    background-color: ${theme.colors.primary};
    cursor: pointer;
  }

  .active.front-arrow::after {
    border-left-color: ${theme.colors.primary};
  }

  .inactive {
    background-color: ${theme.colors.lightPrimary};
    cursor: pointer;
  }

  .inactive.front-arrow::after {
    border-left-color: ${theme.colors.lightPrimary};
  }

  .incomplete .text {
    color: ${theme.colors.neutrals.darkGray};
  }

  .incomplete.front-arrow:after {
    border-left-color: ${theme.colors.neutrals.lightGray};
  }

  .text {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 100%;
    height: 100%;
    color: ${theme.colors.neutrals.white};
    font-weight: 600;
    z-index: 1;
    margin: 0;
  }

  .tablist {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 100%;
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    display: none;
  }
`;

export const ProgressStepper: FC<Props> = ({
  pageNumber,
  pages,
  allPagesActive = true,
}) => {
  const navigate = useNavigate();
  const { activePages } = useSelector((state: RootState) => state.navigation);

  const [active, setActive] = useState<number>(pageNumber);

  function onClick(pageLink: string, pageNumber: number) {
    navigate(pageLink);
    setActive(pageNumber);
  }

  return (
    <Style role="navigation" aria-label="Progress Stepper">
      <ol role="tablist" className="tablist">
        {pages.map((page, pageNumber) => {
          // Generate the styled classes for this tab
          const styledClasses = generateStyledClasses(
            pageNumber,
            active,
            page.id,
            pageNumber === pages.length - 1,
            allPagesActive,
            activePages,
          );

          return (
            <li
              key={pageNumber}
              role="tab"
              tabIndex={0}
              className={styledClasses}
              onClick={
                allPagesActive || activePages.includes(page.id)
                  ? () => onClick(page.link, pageNumber)
                  : () => {}
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") onClick(page.link, pageNumber);
              }}
              aria-label={page.name}
            >
              <p className="text">{page.name}</p>
            </li>
          );
        })}
      </ol>
    </Style>
  );
};

function generateStyledClasses(
  pageNumber: number,
  activePage: number,
  pageId: PageNames,
  isLast: boolean,
  allPagesActive: boolean,
  activePages: PageNames[],
) {
  // Start with the base class
  let styledClasses = "navbar__tab ";

  // If the tab is the first tab, add tab-first class
  if (pageNumber === 0) {
    styledClasses += "tab-first ";
  }

  // If the tab is the last tab, add tab-last class, else add front-arrow class
  if (isLast) {
    styledClasses += "tab-last ";
  } else {
    styledClasses += "front-arrow ";
  }

  // If the tab is the active tab, add active class
  if (pageNumber === activePage) {
    styledClasses += "active ";
  }

  if (allPagesActive) {
    if (pageNumber < activePage) {
      styledClasses += "inactive ";
    }

    if (pageNumber > activePage) {
      styledClasses += "incomplete ";
    }
  } else {
    // If the tab is before the active tab, then it is inactive, add inactive class
    if (pageNumber !== activePage && activePages.includes(pageId)) {
      styledClasses += "inactive ";
    }

    // If the tab is after the active tab, then it is incomplete, add incomplete class
    if (pageNumber !== activePage && !activePages.includes(pageId)) {
      styledClasses += "incomplete ";
    }
  }

  return styledClasses;
}
