import theme from "../theme";
import Button from "./Button";
import { Controller, UseFormReturn } from "react-hook-form";
import { FC, ReactNode, useEffect, useState } from "react";
import { FinancingOptions } from "../types";
import MUIToolTip from "./MUIToolTip";
import TextInput from "./TextInput";
import {
  currencyRegex,
  // integerRegex,
  invalidInputMessage,
  // requiredInputMessage,
} from "../constants";
import styled from "styled-components";
import { removeCommas } from "../utils";
import { moneyFormat } from "../simulations/utils";

interface OptionProps {
  label: string;
  form: UseFormReturn<any>;
  tooltip: ReactNode;
  name: keyof FinancingOptions;
  currentCollegeData: FinancingOptions;
  buttonOnClick?: () => void;
  buttonLabel?: string;
  buttonSize?: string;
  readOnly?: boolean;
  saveInputValue: (newValue: string) => void;
}

const Style = styled.div`
  .input-option {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;

    .input-title {
      display: flex;
      font-weight: 700;
      align-items: center;
      gap: 4px;
    }

    .user-input {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .calculate-button {
      padding: 12px;
    }
  }
`;

const InputOption: FC<OptionProps> = ({
  label,
  form,
  tooltip,
  currentCollegeData,
  name,
  buttonOnClick,
  buttonLabel,
  buttonSize = "180px",
  readOnly = false,
  saveInputValue,
}) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = form;

  const [value, setValue] = useState<string>(
    currentCollegeData[name] as string,
  );

  useEffect(() => {
    setValue(currentCollegeData[name] as string);
  }, [name, currentCollegeData]);

  // 000100 invalid
  // 0.001 invalid
  // 100.00 invalid
  // 10000 valid
  // 000 => default 0
  const trimZero = (value: string) => {
    //default 0
    //check NaN
    if (value === "NaN") return "0";
    if (value === "0" || value === "") return "0";
    //handle invalid leading 0
    if (value === "00") return "0";
    //handle 0.00 and 0100
    if (value[0] === "0" && value[1] !== null && value[1] !== ".")
      return value.slice(1);
    //limit to 2 decimal
    // if (value.includes(".")) {
    //   return value.slice(0, value.indexOf(".") + 3);
    // }
    return value;
  };

  return (
    <Style>
      <div className="input-option">
        <p className="input-title">
          {label}
          <MUIToolTip text={tooltip} anchorName={name} />
        </p>

        <Controller
          name={name}
          control={control}
          rules={{
            // required: requiredInputMessage,
            pattern: {
              value: currencyRegex,
              message: invalidInputMessage,
            },
            // min: {
            //   value: 0,
            //   message: "Please enter a value greater than or equal to 0",
            // },
            max: {
              value: 2147483647,
              message: "Value too large",
            },
          }}
          render={({ field }) => (
            <TextInput
              field={field}
              fullWidth
              value={trimZero(moneyFormat(parseInt(value)))}
              onChange={async (e) => {
                const newValue = removeCommas(e.target.value);

                setValue(newValue);

                const isValid = await trigger(name); // Trigger validation for the field

                if (isValid) {
                  saveInputValue(newValue);
                }
              }}
              adornment="$"
              color={errors[name]?.message ? "red" : theme.colors.neutrals.gray}
              errorMessage={errors[name]?.message?.toString()}
              readOnly={readOnly}
            />
          )}
        />
        {buttonOnClick && (
          <Button
            className="input-button"
            buttonText={buttonLabel}
            handleOnClick={buttonOnClick}
            bgColor={theme.colors.secondary}
            borderColor={theme.colors.secondary}
            size={buttonSize}
            height={"40px"}
          />
        )}
      </div>
    </Style>
  );
};

export default InputOption;
