// import Carousel from "nuka-carousel";
import TeamPic from "../assets/images/mission-team.svg";
import EdEquality from "../assets/partner-icons/Ed_Equity_Lab_Black_Logo.svg";
import FNCLogo from "../assets/partner-icons/FNC-Logo.svg";
import Height from "../assets/partner-icons/heights-logo.svg";
import LabLogo from "../assets/partner-icons/Logo-1.svg";
import PhillySchool from "../assets/partner-icons/PhillySchoolLogo.svg";
import styled from "styled-components";
import theme from "../theme";
// import arrowRight from "../assets/icons/arrow-right.svg";
// import arrowLeft from "../assets/icons/arrow-left.svg";

// interface ControlProps {
//   currentSlide: number;
//   slideCount: number;
//   pagingDotsIndices: number[];
//   nextDisabled: boolean;
//   previousDisabled: boolean;
//   nextSlide(): void;
//   previousSlide(): void;
//   goToSlide(targetIndex: number): void;
// }
const Style = styled.section`
  .mission-partners-title {
    font-size: 20px;
    border-bottom: 5px solid ${theme.colors.primary};
    padding-bottom: 20px;
  }
  .partners {
    display: flex;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      width: 80%;
      margin: auto;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  .row img {
    margin: 3%;
    object-fit: scale-down;
  }
  .carousel-pics {
    object-fit: scale-down;
    width: 100%;
    margin-bottom: 24px;
  }

  .description {
    font-size: 22px;
    line-height: 110%;
    margin-bottom: 36px;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .mission-partners-title {
      text-align: center;
    }

    .row img {
      flex: 1;
      width: 100%;
      height: 15vh;
      object-fit: contains;
      margin: 0;
    }

    .description {
      font-size: 16px;
      text-align: justify;
      width: 80%;
      margin: 1em auto 2em auto;
    }
  }
`;
const partners_logo_row1: string[] = [PhillySchool, LabLogo];
const partners_logo_row2: string[] = [Height, FNCLogo, EdEquality];
function OurStory() {
  // const renderCustomPrevButton = ({
  //   previousSlide,
  //   previousDisabled,
  // }: ControlProps) => <img onClick={previousSlide} src={arrowRight} alt="" />;

  // const renderCustomNextButton = ({
  //   nextSlide,
  //   nextDisabled,
  // }: ControlProps) => <img onClick={nextSlide} src={arrowLeft} alt="" />;
  return (
    <Style>
      <p className="description">
        We built Finiverse in response to the national conversation on the
        incredible burden of student debt in the United States. Our goal with
        Finiverse is to help all high schoolers better understand and navigate
        their college-related finances.
      </p>
      {/* <Carousel
        adaptiveHeight={false}
        autoplay={true}
        cellAlign="center"
        cellSpacing={20}
        renderCenterLeftControls={renderCustomPrevButton}
        renderCenterRightControls={renderCustomNextButton}
      > */}
      {/* TODO: ADD MORE PICS */}
      {/* </Carousel> */}
      <img
        className="carousel-pics"
        src={TeamPic}
        alt="levra lab team members"
      />
      <div className="partner-section">
        <h1 className="mission-partners-title">
          With gratitude to our partners
        </h1>
        <div className="partners">
          <div className="row">
            {partners_logo_row1.map((path) => {
              return <img src={path} alt="partners"></img>;
            })}
            {partners_logo_row2.map((path) => {
              return <img src={path} alt="partners"></img>;
            })}
          </div>
        </div>
      </div>
    </Style>
  );
}

export default OurStory;
