import { FC } from "react";
import theme from "../../theme";
import { BarDatum, LegendLabelDatum, ResponsiveBar } from "@nivo/bar";
import { GraphProps } from "../../types";

const Histogram: FC<GraphProps> = ({
  data,
  colors = [theme.colors.primary],
  layout = "vertical",
  legendLabelMap,
  xAxisKey,
  yAxisKeys,
  xAxisLabel,
  legendWidth = 250,
  yAxisLabel,
  showLegend = true,
  showDollarSign = false,
}) => {
  return (
    <ResponsiveBar
      valueFormat=" >-~%"
      data={data}
      legendLabel={(x: LegendLabelDatum<BarDatum>) => {
        return `${legendLabelMap ? legendLabelMap[x.id] : x.id}`;
      }}
      label={xAxisLabel}
      keys={yAxisKeys}
      layout={layout}
      padding={0}
      labelSkipHeight={100}
      indexBy={xAxisKey}
      margin={{
        top: 20,
        right: 30,
        bottom: 60,
        left: 60,
      }}
      borderWidth={1}
      borderColor={theme.colors.neutrals.white}
      enableGridY={true}
      enableGridX={false}
      valueScale={{ type: "linear" }}
      colors={colors}
      animate={true}
      enableLabel={false}
      maxValue={1}
      axisTop={null}
      axisRight={null}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yAxisLabel,
        legendPosition: "middle",
        legendOffset: -50,
        format: (value) => {
          return `${value * 100}%`;
        },
      }}
      theme={{
        fontSize: 18,
        fontFamily: "Karla",
        legends: { text: { fontSize: 12 } },
        axis: {
          ticks: {
            text: {
              fontSize: 12,
              fill: theme.colors.neutrals.black,
            },
          },
        },
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: window.innerWidth < theme.breakpoints.xlarge ? -50 : 0,
        legend: xAxisLabel,
        legendPosition: "middle",
        legendOffset: -10,
        format: (value) => {
          return `${showDollarSign ? "$" : ""}${value}`;
        },
      }}
      legends={
        showLegend
          ? [
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: -400,
                translateY: -30,
                itemWidth: legendWidth,
                itemHeight: 20,
                itemsSpacing: 1,
                symbolSize: 16,
                itemDirection: "left-to-right",
                toggleSerie: true,
              },
            ]
          : []
      }
      layers={["axes", "grid", "bars", "markers", "legends"]}
    />
  );
};

export default Histogram;
