import { ReactNode } from "react";
import styled from "styled-components";
import Nav from "./Nav";
import { ProgressStepper } from "./Stepper";
import theme from "../theme";
import { pages } from "../constants";
import Footer from "./Footer";

interface PageProps {
  pageNumber?: number;
  titleIcon?: string;
  title: string;
  children?: ReactNode | ReactNode[];
}

const Style = styled.div`
  .content-blue {
    background: ${theme.colors.lightIndigo};
    padding: 3em;
    @media (max-width: ${theme.breakpoints.medium}px) {
      padding: 0;
    }
  }

  .content-white {
    background: ${theme.colors.neutrals.white};
    border-radius: 0px 0px 40px 40px;
    padding: 2em;
    min-height: 90vh;
    @media (max-width: ${theme.breakpoints.large}px) {
      padding: 0;
    }
  }

  .survey-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 65px;

    .header {
      display: flex;
      gap: 18px;
      padding-bottom: 24px;
    }

    .title {
      font-size: 2rem;
      border-bottom: 4px solid ${theme.colors.primary};
      line-height: 150%;
    }

    .children {
      width: 70%;
    }
  }

  .input-background {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .title {
      text-align: left;
      width: 100%;
      font-size: 1.7rem;
    }

    .content-white {
      padding: 24px;
      border-radius: 0;
    }

    .survey-content {
      align-items: flex-start;
    }

    .survey-content > .children {
      width: 100%;
    }
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    .header > img {
      display: none;
    }
    .title {
      text-align: left;
      width: 100%;
      font-size: 1.5rem;
    }
    .content-white {
      padding: 24px;
      border-radius: 0;
    }
    .content-white > .survey-content {
      padding-top: 0px;
    }
    .survey-content > .children {
      width: 100%;
    }
  }
`;

export const PageLayout: React.FC<PageProps> = ({
  pageNumber,
  title,
  titleIcon,
  children,
}) => {
  return (
    <Style>
      <Nav />
      <div className="content-blue">
        {pageNumber !== undefined && (
          <ProgressStepper
            key={pageNumber}
            pageNumber={pageNumber}
            pages={pages}
            allPagesActive={false}
          />
        )}
        <div className="content-white">
          <div className="survey-content">
            <div className="header">
              {titleIcon && <img src={titleIcon} alt="" />}
              <h1 className="title">{title}</h1>
            </div>

            <div className="children">{children}</div>
          </div>
        </div>
      </div>
      <Footer />
    </Style>
  );
};

export default PageLayout;
