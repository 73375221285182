export type ThemeType = typeof LightTheme; // This is the type definition for my theme object.

export const LightTheme = {
  fonts: {
    body: "Karla",
  },
  colors: {
    primary: "#547CFF",
    secondary: "#FF8465",
    lightIndigo: "#C1CFFF",
    creamOrange: "#FFCDC1",
    lightOrange: "#FFE0D8",
    veryLightOrange: "#FFF6F4",
    lightYellow: "#FFE7D5",
    lightPrimary: "#7D9BFF",
    lightGreen: "#AFE5B7",
    lightRed: "#FEB5B5",
    pennBlue: "#004785",
    neutrals: {
      black: "#221919",
      white: "#ffffff",
      gray: "#C4C4C4",
      mediumGray: "#A0A5AB",
      darkGray: "#5A5A5A",
      lightGray: "#EBEBEB",
    },
    graphColors: {
      turquoise: "#22AAB6",
      lavender: "#AA83CE",
      lightLavender: "#E2CDF5",
      redOrange: "#FD6565",
      darkPurple: "#4E467A",
      lightOrange: "#FFAD72",
      eggplantPurple: "#942FD2",
      indigo: "#7D9BFF",
      mediumGreen: "#83CE8F",
    },
  },
  breakpoints: {
    xsmall: 480,
    small: 600,
    medium: 768,
    large: 992,
    xlarge: 1200,
    xxlarge: 1400,
  },
};

const theme = LightTheme; // set the light theme as the default.
export default theme;
