import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../state";
import OrangeStepButtons from "../../components/OrangeStepButtons";
import {
  setCurrentYear,
  updateLoanAccumulationStepIndex,
  updateLoanBalanceSoFar,
} from "../financing-options-slice";
import { YearlyLoanAmount } from "../../types";
import { StepExplanationByIndex, loanAdditionByStep } from "./step-logic";
import { scrollToElement } from "../../utils";

const Style = styled.div`
  .buttons-section {
    padding-top: 24px;
  }
`;

interface ExplanationComponentProps {}

export const ExplanationStep: FC<ExplanationComponentProps> = () => {
  const dispatch = useDispatch();
  const {
    loanAccumulationStepIndex: stepIndex,
    currentYear,
    loanBalanceSoFar,
    totalNumberOfYears,
    currentFinancingOptionsResult,
  } = useSelector((state: RootState) => state.financingOptions);

  const currentYearLoanBreakdown =
    currentFinancingOptionsResult &&
    currentFinancingOptionsResult.annualLoanAccumulationData.find(
      (yearLoanObject: YearlyLoanAmount) => yearLoanObject.year === currentYear,
    );

  if (!currentYearLoanBreakdown) return null;

  return (
    <Style className="outlined-explanation-section">
      {StepExplanationByIndex((stepIndex - 3) % 7, currentYearLoanBreakdown)}

      <div className="buttons-section">
        <OrangeStepButtons
          stepNumber={stepIndex}
          totalSteps={totalNumberOfYears * 7 + 2}
          restartButtonText="Back to Summary"
          backButtonAction={() => {
            // update total balance and appropriate loan balance
            const updatedTotalBalance =
              loanBalanceSoFar.total -
              loanAdditionByStep((stepIndex - 3) % 7, currentYearLoanBreakdown)
                .value;

            const loanAdditionObject = loanAdditionByStep(
              ((stepIndex - 3) % 7) - 1,
              currentYearLoanBreakdown,
            );

            dispatch(
              updateLoanBalanceSoFar({
                ...loanBalanceSoFar,
                total: updatedTotalBalance,
                federalSubsidized: [
                  "federalSubsidizedPrincipalAmt",
                  "federalSubsidizedInterestAmt",
                ].includes(loanAdditionObject.key)
                  ? loanBalanceSoFar.federalSubsidized -
                    loanAdditionObject.value
                  : loanBalanceSoFar.federalSubsidized,
                federalUnsubsidized: [
                  "federalUnsubsidizedPrincipalAmt",
                  "federalUnsubsidizedInterestAmt",
                ].includes(loanAdditionObject.key)
                  ? loanBalanceSoFar.federalUnsubsidized -
                    loanAdditionObject.value
                  : loanBalanceSoFar.federalUnsubsidized,
                private: ["privatePrincipalAmt", "privateInterestAmt"].includes(
                  loanAdditionObject.key,
                )
                  ? loanBalanceSoFar.private - loanAdditionObject.value
                  : loanBalanceSoFar.private,
              }),
            );

            // update current year if necessary
            if ((stepIndex - 3) % 7 === 0) {
              dispatch(
                setCurrentYear(currentYear - 1 >= 0 ? currentYear - 1 : 0),
              );
            }

            // update step index
            dispatch(updateLoanAccumulationStepIndex(stepIndex - 1));
            scrollToElement("loan-accumulation-heading");
          }}
          nextButtonAction={() => {
            // update total balance and appropriate loan balance
            const updatedTotalBalance =
              loanBalanceSoFar.total +
              loanAdditionByStep(
                ((stepIndex - 3) % 7) + 1,
                currentYearLoanBreakdown,
              ).value;

            const loanAdditionObject = loanAdditionByStep(
              (stepIndex - 3) % 7,
              currentYearLoanBreakdown,
            );

            dispatch(
              updateLoanBalanceSoFar({
                ...loanBalanceSoFar,
                total: updatedTotalBalance,
                federalSubsidized: [
                  "federalSubsidizedPrincipalAmt",
                  "federalSubsidizedInterestAmt",
                ].includes(loanAdditionObject.key)
                  ? loanBalanceSoFar.federalSubsidized +
                    loanAdditionObject.value
                  : loanBalanceSoFar.federalSubsidized,
                federalUnsubsidized: [
                  "federalUnsubsidizedPrincipalAmt",
                  "federalUnsubsidizedInterestAmt",
                ].includes(loanAdditionObject.key)
                  ? loanBalanceSoFar.federalUnsubsidized +
                    loanAdditionObject.value
                  : loanBalanceSoFar.federalUnsubsidized,
                private: ["privatePrincipalAmt", "privateInterestAmt"].includes(
                  loanAdditionObject.key,
                )
                  ? loanBalanceSoFar.private + loanAdditionObject.value
                  : loanBalanceSoFar.private,
              }),
            );

            // update current year if necessary
            if ((stepIndex - 3) % 7 === 6) {
              dispatch(
                setCurrentYear(
                  currentYear + 1 <= totalNumberOfYears
                    ? currentYear + 1
                    : totalNumberOfYears,
                ),
              );
            }

            // update step index
            dispatch(updateLoanAccumulationStepIndex(stepIndex + 1));
            scrollToElement("loan-accumulation-heading");
          }}
          restartButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(0));
            dispatch(setCurrentYear(1));
            dispatch(
              updateLoanBalanceSoFar({
                total: 0,
                federalSubsidized: 0,
                federalUnsubsidized: 0,
                private: 0,
              }),
            );
            scrollToElement("loan-accumulation-heading");
          }}
        />
      </div>
    </Style>
  );
};
