import { FC, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state";
import OrangeStepButtons from "../../../components/OrangeStepButtons";
import {
  setCurrentYear,
  updateLoanAccumulationStepIndex,
  updateLoanBalanceSoFar,
} from "../../financing-options-slice";
import { LoanFigureComponent } from "../../loan-figure-component";
import { scrollToElement } from "../../../utils";

const Style = styled.div`
  .buttons-section {
    padding-top: 24px;
  }

  .orange-text {
    color: ${theme.colors.secondary};
  }
`;

export const AboutStudentLoans: FC = () => {
  const dispatch = useDispatch();
  const stepIndex = useSelector(
    (state: RootState) => state.financingOptions.loanAccumulationStepIndex,
  );

  useEffect(() => {
    dispatch(
      updateLoanBalanceSoFar({
        total: 0,
        federalSubsidized: 0,
        federalUnsubsidized: 0,
        private: 0,
      }),
    );
    dispatch(setCurrentYear(1));
  }, [dispatch]);

  return (
    <Style className="outlined-explanation-section">
      <h3>About Student Loans</h3>
      <p>
        You can also take loans when you go to college. If you don’t have enough
        money to pay the cost of attendance, you can take out a loan from the
        government (this is a <b>federal loan</b> ) or from a bank (this is a{" "}
        <b>private loan)</b>.
        <br /> <br /> It’s better for you to take out federal loans than private
        loans because federal loans have <b>lower</b> interest rates.
        <br /> <br /> Federal loans can be <b>subsidized</b> or{" "}
        <b>unsubsidized</b>. Subsidized loans don’t collect any interest until
        you graduate; unsubsidized loans will collect interest while you are in
        college.
      </p>
      <div className="rates-section">
        <LoanFigureComponent
          label="Federal subsidized loans interest rate"
          value="0%"
          footnote="*It is 0% while you’re in college, and 5.5% after you graduate."
          textColor={theme.colors.graphColors.eggplantPurple}
          paddingBottom={24}
          paddingTop={24}
        />

        <LoanFigureComponent
          label="Federal unsubsidized loans interest rate"
          value="5.5%"
          footnote="*It is 5.5% regardless of whether you are in or out of college."
          textColor={theme.colors.graphColors.mediumGreen}
          paddingBottom={24}
          paddingTop={24}
        />
        <LoanFigureComponent
          label="Private loans interest rate"
          value="8%"
          footnote={
            <span>
              *Different private lenders have different interest rates, but 8%
              is the average as of 2023 (Source:{" "}
              <a
                href="https://www.forbes.com/advisor/student-loans/student-loan-rates-11-20-23/"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.lightPrimary,
                }}
              >
                Forbes
              </a>
              ).
            </span>
          }
          textColor={theme.colors.graphColors.indigo}
          paddingBottom={24}
          paddingTop={24}
        />
      </div>
      <p>
        Next, we’re going to show you how your loans will accumulate each year
        during college.
      </p>
      <div className="buttons-section">
        <OrangeStepButtons
          stepNumber={stepIndex}
          totalSteps={10}
          restartButtonText="Back to Summary"
          backButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(1));
            scrollToElement("loan-accumulation-heading");
          }}
          nextButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(3));
            scrollToElement("loan-accumulation-heading");
          }}
          restartButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(0));
            dispatch(setCurrentYear(1));
            dispatch(
              updateLoanBalanceSoFar({
                total: 0,
                federalSubsidized: 0,
                federalUnsubsidized: 0,
                private: 0,
              }),
            );
            scrollToElement("loan-accumulation-heading");
          }}
        />
      </div>
    </Style>
  );
};
