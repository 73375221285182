import styled from "styled-components";
import { LoanFigureComponent } from "../student-loans/loan-figure-component";
import { moneyFormat } from "../simulations/utils";
import theme from "../theme";

interface EquationLayoutProps {
  className: string;
  addends: { label: string; value: number; tooltipText: string }[];
  sum: { label: string; value: number; tooltipText: string };
}

const Style = styled.div`
  width: 100%;
  .addend,
  .sum {
    width: 100%;
    flex-direction: row;
    .label {
      flex: 5;
    }
    .value {
      flex: 1;
    }
  }

  .math-symbol {
    color: ${theme.colors.neutrals.mediumGray};
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    width: 6%;
  }

  .first-math-symbol {
    visibility: hidden;
  }

  .addend-row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  hr {
    border-bottom: 2px solid ${theme.colors.neutrals.mediumGray};
  }
`;
const EquationLayout: React.FC<EquationLayoutProps> = ({
  className,
  addends,
  sum,
}) => {
  return (
    <Style className={className}>
      {addends.map(({ label, value, tooltipText }, index) => {
        return (
          <div className="addend-row">
            <div
              className={`math-symbol ${
                index === 0 ? "first-math-symbol" : ""
              }`}
            >
              -
            </div>
            <LoanFigureComponent
              className="addend"
              label={label}
              value={"$" + moneyFormat(value)}
              textColor={theme.colors.neutrals.mediumGray}
              tooltipText={tooltipText}
              width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 30}
            />
          </div>
        );
      })}

      <hr />

      <LoanFigureComponent
        className="sum"
        label={sum.label}
        value={"$" + moneyFormat(sum.value)}
        textColor={theme.colors.primary}
        tooltipText={sum.tooltipText}
        width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 25}
      />
    </Style>
  );
};

export default EquationLayout;
