import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state";
import { setSelectedCollegeOptions } from "./college-costs-slice";
import { College } from "../types";
import InputRow from "../components/InputRow";
import Button from "../components/Button";
import styled from "styled-components";
import US_STATES from "./US_STATES";
import theme from "../theme";
import { addNewFinancingOptionBySchoolId } from "../student-loans/financing-options-slice";

interface CollegeCostsInputsProps {
  initialSchool?: College;
  initialUSState?: string;
  showOverlay: boolean;
  hideOverlay: () => void;
  calculateCosts: () => void;
}

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .inputs {
    width: 100%;
    padding: 24px;
  }

  .error {
    color: ${theme.colors.graphColors.redOrange};
    font-weight: 700px;
    padding-bottom: 40px;
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .inputs {
      width: 90%;
    }
    .button-container {
      width: 90%;

      .submit-button {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

const selectCollegeCostsState = (state: RootState) => state.collegeCosts;

export const CollegeCostsInputs: React.FC<CollegeCostsInputsProps> = ({
  initialSchool = undefined,
  showOverlay,
  hideOverlay,
  calculateCosts,
}) => {
  const dispatch = useDispatch();
  const { dropdownOptions, selectedOptions, results } = useSelector(
    selectCollegeCostsState,
  );

  const [selectedSchool, setSelectedSchool] = useState(initialSchool);
  const [errorMessage, setErrorMessage] = useState("");

  // functions to handle local input state changes
  const handleUSStateChange = (event: any, value: any) => {
    if (value) {
      const selectedUSState = value.label;
      dispatch(
        setSelectedCollegeOptions({
          ...selectedOptions,
          stateOfResidence: selectedUSState,
        }),
      );
    }
  };

  const handleSchoolChange = (event: any, value: any) => {
    let selectedCollege = null;
    if (value) {
      const selectedSchoolName = value.label;
      selectedCollege = dropdownOptions.colleges.find(
        (college: { name: string }) => college.name === selectedSchoolName,
      );
    }
    if (selectedCollege) {
      setSelectedSchool(selectedCollege);
      dispatch(
        setSelectedCollegeOptions({
          ...selectedOptions,
          college: selectedCollege,
        }),
      );
    }
  };

  return (
    <Style>
      {showOverlay && <h2>Add New School</h2>}
      <p>Add a school to see how much it would cost to attend:</p>
      <div className="inputs">
        <InputRow
          rowLabel="School name"
          fieldTitle="School"
          placeholderValue="Select school"
          selectedValue={selectedSchool ? selectedSchool.name : ""}
          dropdownOptions={dropdownOptions.colleges.map(
            (college) => college.name,
          )}
          handleChange={handleSchoolChange}
        />
        <InputRow
          rowLabel="State that you live in"
          fieldTitle="State"
          selectedValue={selectedOptions.stateOfResidence}
          dropdownOptions={Object.keys(US_STATES)}
          handleChange={handleUSStateChange}
        />
      </div>
      {errorMessage && <div className="error">{errorMessage}</div>}
      <div className="button-container">
        <Button
          styleClassName="submit-button"
          buttonText={"Submit"}
          handleOnClick={() => {
            if (!selectedSchool || !selectedOptions.stateOfResidence) {
              setErrorMessage("You must select a school and a state.");
            } else if (results.length === 4) {
              setErrorMessage("You can only add up to 4 schools.");
            } else if (
              results.some(
                (result) => result.schoolName === selectedSchool?.name,
              )
            ) {
              setErrorMessage("This school has already been added.");
            } else {
              calculateCosts();
              dispatch(
                addNewFinancingOptionBySchoolId(selectedSchool.collegeId),
              );
              showOverlay && hideOverlay();
            }
          }}
        />
      </div>
    </Style>
  );
};

export default CollegeCostsInputs;
