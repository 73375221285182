import TextCallout from "../../components/TextCallout";
import { moneyFormat } from "../../simulations/utils";
import { YearlyLoanAmount } from "../../types";
import { ExplanationStep } from "./ExplanationComponent";
import { AboutStudentLoans } from "./intro-explanations/AboutStudentLoans";
import { HowLoansWork } from "./intro-explanations/HowLoansWork";
import { LoanAccumulationSummary } from "./intro-explanations/Summary";

export const LoanStepLogic = (index: number) => {
  if (index <= 0) {
    return <LoanAccumulationSummary />;
  } else if (index === 1) {
    return <HowLoansWork />;
  } else if (index === 2) {
    return <AboutStudentLoans />;
  } else if (index >= 3) {
    return <ExplanationStep />;
  } else {
    return <LoanAccumulationSummary />;
  }
};

export const loanAdditionByStep = (
  index: number,
  loanBreakdownThisYear: YearlyLoanAmount,
) => {
  const {
    federalSubsidizedPrincipalAmt,
    federalSubsidizedInterestAmt,
    federalUnsubsidizedInterestAmt,
    federalUnsubsidizedPrincipalAmt,
    privateInterestAmt,
    privatePrincipalAmt,
  } = loanBreakdownThisYear;

  switch (index) {
    case 0:
      return {
        key: "",
        label: "",
        value: 0,
      };
    case 1:
      return {
        key: "federalSubsidizedPrincipalAmt",
        label: "LOAN AMOUNT",
        value: federalSubsidizedPrincipalAmt,
      };
    case 2:
      return {
        key: "federalUnsubsidizedPrincipalAmt",
        label: "LOAN AMOUNT",
        value: federalUnsubsidizedPrincipalAmt,
      };
    case 3:
      return {
        key: "privatePrincipalAmt",
        label: "LOAN AMOUNT",
        value: privatePrincipalAmt,
      };
    case 4:
      return {
        key: "federalSubsidizedInterestAmt",
        label: "INTEREST (0%)",
        value: federalSubsidizedInterestAmt,
      };
    case 5:
      return {
        key: "federalUnsubsidizedInterestAmt",
        label: "INTEREST (5.5%)",
        value: federalUnsubsidizedInterestAmt,
      };
    case 6:
      return {
        key: "privateInterestAmt",
        label: "INTEREST (8%)",
        value: privateInterestAmt,
      };
    default:
      return {
        key: "",
        label: "",
        value: 0,
      };
  }
};

export const StepExplanationByIndex = (
  index: number,
  loanBreakdownThisYear: YearlyLoanAmount,
) => {
  const {
    year,
    totalLoanPrincipalThisYear,
    federalLoanMax,
    federalSubsidizedLoanMax,
    federalSubsidizedPrincipalAmt,
    federalUnsubsidizedInterestAmt,
    federalUnsubsidizedPrincipalAmt,
    federalUnsubsidizedLoanBalanceFromLastYear,
    privateInterestAmt,
    privatePrincipalAmt,
    privateLoanBalanceFromLastYear,
  } = loanBreakdownThisYear;

  switch (index) {
    case 0:
      if (year === 1) {
        return (
          <p>
            In Year {year}, you need to borrow{" "}
            <b className="orange-text">
              ${moneyFormat(totalLoanPrincipalThisYear)}
            </b>{" "}
            in student loans. <br />
            <br />
            Let’s see how much of each loan type you could borrow.
          </p>
        );
      } else {
        return (
          <p>
            In Year {year}, you need to borrow{" "}
            <b className="orange-text">
              ${moneyFormat(totalLoanPrincipalThisYear)}
            </b>
            . <br />
            <br />
            Let’s see how much of each loan type to borrow.
          </p>
        );
      }
    case 1:
      return (
        <>
          <p>
            Remember that you need to borrow{" "}
            <b className="orange-text">
              ${moneyFormat(totalLoanPrincipalThisYear)}
            </b>{" "}
            in student loans in Year {year}. According to the office of{" "}
            <a href="https://studentaid.gov/understand-aid/types/loans/subsidized-unsubsidized">
              Federal Student Aid
            </a>
            , you can only borrow up to $
            <b>{moneyFormat(federalSubsidizedLoanMax)}</b> in federal subsidized
            loans in Year {year}. Let's borrow{" "}
            <b className="purple-text">
              ${moneyFormat(federalSubsidizedPrincipalAmt)}
            </b>
            .
            <br />
            <br />
            Remember, federal subsidized loans are loans from the government
            that don’t collect interest until graduation. Once you graduate, the
            interest rate for these loans is still <b>lower</b> than that of
            private loans.
          </p>
          <TextCallout
            text={
              <>
                You always want to borrow the <b>maximum</b> amount of federal
                subsidized loans each year because they don't accumulate
                interest during school!
              </>
            }
          />
        </>
      );
    case 2:
      return (
        <>
          <p>
            Remember that you need to borrow{" "}
            <b className="orange-text">
              ${moneyFormat(totalLoanPrincipalThisYear)}
            </b>{" "}
            in student loans in Year {year}. You've already borrowed{" "}
            <b className="purple-text">
              ${moneyFormat(federalSubsidizedPrincipalAmt)}
            </b>{" "}
            in federal subsidized loans.
            <br />
            <br />
            You can only borrow up to <b>${moneyFormat(federalLoanMax)}</b> in
            total federal loans (subsidized or unsubsidized) in Year {year}, so
            you would borrow{" "}
            <b className="orange-text">
              ${moneyFormat(totalLoanPrincipalThisYear)}
            </b>{" "}
            -{" "}
            <b className="purple-text">
              ${moneyFormat(federalSubsidizedPrincipalAmt)}
            </b>{" "}
            =
            <b className="green-text">
              ${moneyFormat(federalUnsubsidizedPrincipalAmt)}
            </b>{" "}
            in federal unsubsidized loans (Source:{" "}
            <a href="https://studentaid.gov/understand-aid/types/loans/subsidized-unsubsidized">
              Federal Student Aid
            </a>
            ).
            <br />
            <br /> Remember, federal unsubsidized loans <b>do</b> collect{" "}
            <b>5.5%</b> interest regardless of whether you’re in school.
          </p>
          <TextCallout
            text={
              <>
                You should always borrow the <b>maximum</b> amount of federal
                loans before borrowing private loans because the 5.5% interest
                rate of federal loans is usually much lower than the 8% average
                interest rate of private loans!
              </>
            }
          />
        </>
      );
    case 3:
      return (
        <p>
          Remember that you need to borrow{" "}
          <b className="orange-text">
            ${moneyFormat(totalLoanPrincipalThisYear)}
          </b>{" "}
          in student loans in Year {year}.
          <br />
          <br /> The remaining amount is{" "}
          <b className="orange-text">
            ${moneyFormat(totalLoanPrincipalThisYear)}
          </b>{" "}
          -{" "}
          <b className="purple-text">
            ${moneyFormat(federalSubsidizedPrincipalAmt)}
          </b>{" "}
          -{" "}
          <b className="green-text">
            ${moneyFormat(federalUnsubsidizedPrincipalAmt)}
          </b>{" "}
          = <b className="blue-text">${moneyFormat(privatePrincipalAmt)}</b>,
          which will be our private loan amount.
        </p>
      );
    case 4:
      return (
        <p>
          There is <b className="purple-text">no</b> interest for federal
          subsidized loans while you are in school. <br />
          <br />
          You only need to start paying a <b>5.5%</b> interest rate on these
          loans once you graduate.
        </p>
      );
    case 5:
      return (
        <p>
          The interest rate for federal subsidized loans is <b>5.5%</b> both
          during and after college. <br />
          <br />
          Your interest for your federal unsubsidized loans could be{" "}
          <b className="green-text">
            $
            {moneyFormat(
              federalUnsubsidizedLoanBalanceFromLastYear +
                federalUnsubsidizedPrincipalAmt,
            )}
          </b>{" "}
          x 0.055 =
          <b className="green-text">
            ${moneyFormat(federalUnsubsidizedInterestAmt)}
          </b>
          . <br />
          <br />
          That means your total federal unsubsidized loan balance would be{" "}
          <b className="green-text">
            $
            {moneyFormat(
              federalUnsubsidizedLoanBalanceFromLastYear +
                federalUnsubsidizedPrincipalAmt,
            )}{" "}
            + ${moneyFormat(federalUnsubsidizedInterestAmt)} = $
            {moneyFormat(
              federalUnsubsidizedLoanBalanceFromLastYear +
                federalUnsubsidizedPrincipalAmt +
                federalUnsubsidizedInterestAmt,
            )}
          </b>
          .
        </p>
      );
    case 6:
      return (
        <p>
          The average interest rate for private loans is about <b>8%</b>.<br />
          <br /> So the interest for your private loans could be about{" "}
          <b className="blue-text">
            ${moneyFormat(privateLoanBalanceFromLastYear + privatePrincipalAmt)}
          </b>{" "}
          x 0.08 =
          <b className="blue-text">${moneyFormat(privateInterestAmt)}</b>
          . <br />
          <br />
          That means your total private loan balance would be about{" "}
          <b className="blue-text">
            ${moneyFormat(privateLoanBalanceFromLastYear + privatePrincipalAmt)}{" "}
            + ${moneyFormat(privateInterestAmt)} = $
            {moneyFormat(
              privateLoanBalanceFromLastYear +
                privatePrincipalAmt +
                privateInterestAmt,
            )}
          </b>
          .
        </p>
      );
    default:
      <p></p>;
  }
};
