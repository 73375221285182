import { createSlice } from "@reduxjs/toolkit";
import { CollegeCostsState } from "../types";

const initialState: CollegeCostsState = {
  dropdownOptions: {
    colleges: [],
    majors: [],
    programsOfStudy: [],
    householdIncomeBrackets: [],
  },
  showStickerPriceResults: false,
  showNetPriceResults: false,
  selectedOptions: {
    college: undefined,
    programOfStudy: "",
    major: "",
    stateOfResidence: "",
  },
  results: [],
};

const slice = createSlice({
  name: "collegeCosts",
  initialState,
  reducers: {
    getAllDropdownOptions: (state, action) => {
      state.dropdownOptions = action.payload;
    },
    fetchCollegeCostResults: (state, action) => {
      // state.results.push(action.payload);
      // //update all previous US State results
      // state.results.forEach((res) => {
      //   res.inState = action.payload.inState;
      // });
      state.results = action.payload;
    },
    setSelectedCollegeOptions: (state, action) => {
      state.selectedOptions = action.payload;
    },
    setShowResults: (state, action) => {
      state.showStickerPriceResults = action.payload;
    },
    setShowNetPriceResults: (state, action) => {
      state.showNetPriceResults = action.payload;
    },
    removeResults: (state, action) => {
      state.results = state.results.filter(
        (college) => college.collegeId !== action.payload,
      );
    },
  },
});

export const {
  fetchCollegeCostResults,
  getAllDropdownOptions,
  setSelectedCollegeOptions,
  setShowResults,
  setShowNetPriceResults,
  removeResults,
} = slice.actions;

export default slice.reducer;
