import { CardActionArea } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";

interface HorizontalCardProps {
  title: string;
  date: string;
  sourceName?: string;
  description: string | JSX.Element;
  link: string;
  buttonText: string;
}

const Style = styled.div`
  .subline {
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    color: ${theme.colors.neutrals.darkGray};
  }

  h1 {
    font-size: 1.4rem;
  }
`;

const HorizontalCard: FC<HorizontalCardProps> = ({
  title,
  description,
  sourceName,
  date,
  buttonText,
  link,
}) => {
  return (
    <Style>
      <CardActionArea href={link}>
        <Card sx={{ minWidth: 275 }} variant="outlined">
          <CardContent>
            <p className="subline">
              {date} {sourceName && " • "} {sourceName}
            </p>
            <h1>{title}</h1>
            <p>{description}</p>
          </CardContent>
          <CardActions>
            <Button size="small">
              <b>{buttonText}</b>
            </Button>
          </CardActions>
        </Card>
      </CardActionArea>
    </Style>
  );
};

export default HorizontalCard;
