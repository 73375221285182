import { FC } from "react";
import styled from "styled-components";
import { moneyFormat } from "../../simulations/utils";
import theme from "../../theme";

interface AddedLoanAmountProps {
  amount: number;
  label: string;
  bgColor: string;
}

interface AddedLoanAmountStyleProps {
  bgColor: string;
}

const Style = styled.div<AddedLoanAmountStyleProps>`
  background: ${(props) => props.bgColor};
  color: ${theme.colors.neutrals.white};
  padding: 12px 8px;
  width: fit-content;
  @media (max-width: ${theme.breakpoints.xsmall}px) {
    width: inherit;
    font-size: 12px;
  }
`;

export const AddedLoanAmount: FC<AddedLoanAmountProps> = ({
  amount,
  label,
  bgColor,
}) => {
  return (
    <Style bgColor={bgColor}>
      <span>
        <b>+ ${moneyFormat(amount)}</b> {label}
      </span>
    </Style>
  );
};
