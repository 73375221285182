import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import homeIcon from "../assets/images/Wharton Finiverse Logo.png";
import hamburgerMenuIcon from "../assets/icons/hamburger-menu.svg";
import exitIcon from "../assets/icons/x-white.svg";
import chevronDown from "../assets/icons/chevron-down.svg";
import chevronUp from "../assets/icons/chevron-up.svg";
import theme from "../theme";
import { useEffect, useState } from "react";
import { Pages } from "../constants";
import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../state";
import { setShowSummaryButton } from "../home/navigation-slice";
import InstagramIcon from "../assets/icons/instagram.svg";

const Style = styled.nav`
  max-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;

  // Hide the mobile nav content by default
  .mobile-nav-container,
  .mobile-app-name {
    display: none;
  }

  .left-nav-container {
    display: flex;
    align-items: center;
    gap: 16px;
    flex: 10;

    .home-icon {
      height: 60px;
      cursor: pointer;
    }
  }

  .app-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
  }

  .app-name {
    font-size: 24px;
    font-weight: 700;
    color: ${theme.colors.neutrals.black};
  }

  a {
    text-decoration: none;
  }

  .about-us-link {
    flex-grow: 1;
    color: ${theme.colors.pennBlue};

    @media print {
      display: none;
    }
  }

  .about-us-link:hover {
    color: ${theme.colors.primary};
  }

  .nav-item {
    flex: 1;
    list-style-type: none;
  }

  .social-media-icons {
    margin-left: 16px;
    margin-right: 16px;
    width: 36px;
  }

  .summary-button {
    width: 200px;
    height: 50px;
    @media print {
      display: none;
    }
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    background-color: ${theme.colors.primary};
    padding: 20px 30px;
    height: 50px;

    .left-nav-container {
      .home-icon {
        height: 40px;
      }

      .app-name {
        display: none;
      }

      .mobile-app-name {
        display: block;
      }
    }

    .mobile-nav-container {
      display: block;

      ul {
        list-style-type: none;
        padding: 0;
      }

      .hamburger-menu {
        background: none;
        border: none;
        cursor: pointer;
      }

      .mobile-nav-content {
        position: absolute;
        left: 0;
        top: 90px;
        width: 100%;
        background-color: ${theme.colors.neutrals.white};
        margin: 0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        z-index: 100;

        .mobile-nav-item {
          .item-content {
            display: flex;
            justify-content: space-between;
          }

          .item-title {
            font-size: 24px;
            font-weight: 500;
            padding: 25px;
            margin: 0;
            display: block;
            color: ${theme.colors.neutrals.black};
            line-height: 1.2;
          }

          .arrow-icon {
            margin-right: 40px;
            cursor: pointer;
          }

          .mobile-nav-sub-item {
            .item-title {
              font-weight: 400;
              padding-left: 40px;
            }

            .active-item {
              background-color: ${theme.colors.primary};
              color: ${theme.colors.neutrals.white};
              font-weight: 700;
            }
          }
        }

        // Divider
        .mobile-nav-item:nth-child(odd) {
          border-bottom: 2px solid ${theme.colors.neutrals.lightGray};
        }
      }
    }

    .home-icon {
      filter: brightness(1) invert(0);
    }

    .app-name {
      font-size: 30px;
      color: ${theme.colors.neutrals.white};
    }

    .nav-item {
      display: none;
    }
  }
`;

export const Nav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // check if download summary is ready
  const { collegeCosts, financingOptions } = useSelector(
    (state: RootState) => state,
  );
  const [showSummaryText, toggleSummaryText] = useState(
    location.pathname === "/summary",
  );

  // TODO: update summary to show results from new simplified loan page & enable summary button again
  // const showSummaryButton = useSelector(
  //   (state: RootState) => state.navigation.showSummaryButton,
  // );

  const showSummaryButton = false;

  // For the hamburger menu
  const [showMenu, setShowMenu] = useState(false);
  const [openDropdown, setOpenDropdown] = useState("");

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(dropdown === openDropdown ? "" : dropdown);
  };

  const getTitle = () => {
    if (location.pathname === "/") {
      return "Finiverse";
    }
    for (const subPages of Object.values(Pages)) {
      for (const subPage of subPages) {
        if (subPage.link === location.pathname) {
          return subPage.name;
        }
      }
    }
  };

  useEffect(() => {
    if (
      collegeCosts.results.length > 0 &&
      financingOptions.selectedJob &&
      financingOptions.results.length === collegeCosts.results.length
    ) {
      dispatch(setShowSummaryButton(true));
    } else {
      dispatch(setShowSummaryButton(false));
    }
  }, [dispatch, collegeCosts, financingOptions]);

  return (
    <Style className="nav" role="navigation">
      <section className="left-nav-container">
        {/* Regular app name for larger screens */}
        <Link to="/" className="app-name">
          <img className="home-icon" src={homeIcon} alt="home" />
        </Link>

        {/* App name displays page title for smaller screens */}
        <Link to="/" className="app-name mobile-app-name">
          {getTitle()}
        </Link>
      </section>
      {showSummaryButton && (
        <span className="nav-item">
          <Button
            className="summary-button"
            handleOnClick={() => {
              showSummaryText ? navigate("/") : navigate("/summary");
              toggleSummaryText(!showSummaryText);
            }}
            buttonText={showSummaryText ? "Hide Summary" : "View Summary"}
            bgColor={theme.colors.secondary}
            borderColor={theme.colors.secondary}
          />
        </span>
      )}
      <ul role="menu">
        <li role="none" className="nav-item">
          <Link role="menuitem" to="/our-mission" className="about-us-link">
            About Us
          </Link>
        </li>
      </ul>
      <a href="https://www.instagram.com/finiversewharton/">
        <img
          className="social-media-icons"
          src={InstagramIcon}
          alt="Follow our Instagram"
        />
      </a>

      <section className="mobile-nav-container">
        <button className="hamburger-menu" onClick={toggleMenu}>
          <img
            src={showMenu ? exitIcon : hamburgerMenuIcon}
            alt="hamburger menu icon"
          />
        </button>
        {showMenu && (
          <ul className="mobile-nav-content" role="menu">
            {Object.entries(Pages).map(([mainPage, subPages]) => (
              <li className="mobile-nav-item" key={mainPage} role="menuitem">
                <div className="item-content">
                  <Link
                    to={subPages[0].link}
                    className="item-title"
                    onClick={toggleMenu}
                  >
                    {mainPage}
                  </Link>
                  <img
                    className="arrow-icon"
                    src={openDropdown === mainPage ? chevronUp : chevronDown}
                    alt="Expand dropdown links"
                    onClick={() => toggleDropdown(mainPage)}
                    role="button"
                  />
                </div>
                {openDropdown === mainPage && (
                  <ul className="mobile-nav-sublist">
                    {subPages.map((subPage) => (
                      <li
                        className="mobile-nav-sub-item"
                        key={subPage.name}
                        role="menuitem"
                      >
                        <Link
                          to={subPage.link}
                          className={`item-title ${
                            location.pathname === subPage.link
                              ? "active-item"
                              : ""
                          }`} // Add styling for the current page
                          onClick={toggleMenu}
                        >
                          {subPage.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        )}
      </section>
    </Style>
  );
};

export default Nav;
