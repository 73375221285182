import Tabs from "@mui/material/Tabs";
import { useSelector } from "react-redux";
import { RootState } from "../state";
import Tab from "@mui/material/Tab";
import SummaryCard from "./SummaryCard";
import CollegeCostSummary from "./CollegeCostSummary";
import { moneyFormat } from "../simulations/utils";
import styled from "styled-components";
import theme from "../theme";
import { makeStyles } from "@mui/styles";
import StudentLoanSummary from "./StudentLoanSummary";
import RiskSimSummary from "./RiskSimSummary";
import { CollegeFinancialSources } from "../types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  indicator: {
    backgroundColor: theme.colors.primary,
    height: "5px",
    marginTop: "10px",
  },
});

const Style = styled.div`
  .school-tab {
    font-family: "Karla";
    font-size: 28px;
    text-transform: capitalize;
    font-weight: bold;
    color: ${theme.colors.primary};
    padding-bottom: 20px;
  }

  .summary-content {
    padding: 32px 50px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media print {
      padding: 12px 0 0 0;
      gap: 12px;
    }
  }

  .summary-card-title {
    font-size: 24px;
    font-weight: 700;
  }

  .page-break {
    page-break-after: always;
  }
`;

const SummaryContent = () => {
  const navigate = useNavigate();
  const selectFinancingOptionsState = (state: RootState) =>
    state.financingOptions;
  const selectCollegeCostsState = (state: RootState) => state.collegeCosts;

  const { collegeFinancialSources, selectedJob } = useSelector(
    selectFinancingOptionsState,
  );
  const { results: collegeCostResults } = useSelector(selectCollegeCostsState);
  const classes = useStyles();

  useEffect(() => {
    if (collegeCostResults.length === 0 && !selectedJob) {
      navigate("/");
    }
  }, [navigate, collegeCostResults, selectedJob]);

  return (
    <Style>
      {collegeCostResults.map((collegeCostResult, index) => (
        <div
          className={`${
            index !== collegeCostResults.length - 1 ? "page-break" : ""
          }`}
        >
          <Tabs
            className="school"
            value={collegeCostResult.collegeId}
            aria-label="Selected Schools"
            centered={true}
            variant="fullWidth"
            textColor="inherit"
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              className="school-tab"
              label={index + 1 + ".  " + collegeCostResult.schoolName}
              value={collegeCostResult.collegeId}
              id={collegeCostResult.schoolName}
            />
          </Tabs>
          <div className="summary-content">
            <SummaryCard
              title="College Costs"
              children={
                <CollegeCostSummary
                  totalCost={moneyFormat(collegeCostResult.totalAnnualCost)}
                  results={collegeCostResult}
                  financialSources={
                    collegeFinancialSources.find(
                      (college) =>
                        college.collegeId === collegeCostResult.collegeId,
                    ) as CollegeFinancialSources
                  }
                />
              }
            />
            <SummaryCard
              title="Student Loans"
              children={
                <StudentLoanSummary
                  selectedCollegeId={collegeCostResult.collegeId}
                />
              }
            />
            <SummaryCard
              title="Risk Simulation"
              children={
                <RiskSimSummary
                  selectedCollegeId={collegeCostResult.collegeId}
                />
              }
            />
          </div>
        </div>
      ))}
    </Style>
  );
};

export default SummaryContent;
