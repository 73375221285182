import React from "react";
import Team from "../assets/team-pic.svg";
import Prof from "../assets/professor.svg";
import ProductIcon from "../assets/icons/product-design-icon.svg";
import DataIcon from "../assets/icons/data-research-icon.svg";
import CodingIcon from "../assets/icons/coding-icon.svg";
import theme from "../theme";
import styled from "styled-components";

const Style = styled.section`
  p {
    font-size: 20px !important;
  }
  .team-pic {
    width: 100%;
  }
  .team_caption {
    font-style: italic;
    /* padding-top: 10px; */
  }
  .team-context-title {
    font-size: 20px;
    border-bottom: 5px solid ${theme.colors.primary};
    padding-bottom: 20px;
  }
  .team-context-content {
    display: flex;
    align-items: flex-start;
  }
  .team-context-content img {
    margin-right: 5%;
    padding-top: 24px;
  }
  .team-context-content-right h2 {
    font-size: 36px;
  }
  .first-para {
    font-weight: lighter;
  }
  .first-para a {
    color: ${theme.colors.primary};
    text-decoration: none;
    border-bottom: 1px solid;
  }
  .team-context-content-para {
    display: flex;
  }
  .team-context-content-para img {
    margin: 30px;
  }
  .content-description h2 {
    margin-top: 40px;
  }

  @media (max-width: ${theme.breakpoints.xlarge}px) {
    .team-context-content {
      flex-direction: column;
      align-items: flex-start;

      img {
        margin-right: 0;
        padding-top: 0px !important;
      }
    }
  }

  @media (max-width: ${theme.breakpoints.medium}px) {
    .team-pic {
      margin-top: 1em;
    }

    .team_caption {
      text-align: center;
      font-size: 1em;
    }

    .team-context-title {
      margin-top: 45px;
      text-align: center;
      font-size: 22px;
      padding-bottom: 10px;
    }

    .team-context-content {
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      img {
        margin-right: 0;
      }
    }

    .team-context-content-right h2 {
      font-size: 20px;
      text-align: center;
    }

    .first-para,
    .sec-para {
      font-size: 16px;
      padding: 5px 40px;
      margin: 16px 0 0 0;
    }

    .sec-para {
      text-align: justify;
    }

    .team-context-content-para {
      padding: 0 40px;
      margin-bottom: 24px;
    }

    .team-context-content-para img {
      width: 40px;
      margin: 0 30px 0 0;
    }

    .content-description {
      h2 {
        margin-top: 15px;
        margin-bottom: 10px;
        font-size: 18px;
      }

      .team-context-description {
        margin: 0;
        font-size: 15px;
      }
    }
  }
`;

function OurTeam() {
  return (
    <Style>
      <img
        className="team-pic"
        src={Team}
        alt="spring 2023 cohort of student interns"
      ></img>
      <p className="team_caption">Our Spring 2023 cohort of student interns!</p>
      <div className="team-context-section">
        <h1 className="team-context-title">Project Lead</h1>
        <div className="team-context-content">
          <img src={Prof} alt="Professor David Musto" />
          <div className="team-context-content-right">
            <h2>Professor David Musto</h2>
            <p className="first-para">
              Ronald O. Perelman Professor in Finance, The Wharton School, and
              Faculty Director,{" "}
              <a href="https://stevenscenter.wharton.upenn.edu/">
                The Stevens Center for Innovation in Finance
              </a>
            </p>

            <p className="sec-para">
              David Musto is the Faculty Director of the Stevens Center and the
              Ronald O. Perelman Professor in Finance at the Wharton School of
              the University of Pennsylvania. He has served as Chair of
              Wharton’s Finance Department and as a Senior Economist at the
              Securities and Exchange Commission. He received his BA from Yale
              in 1987 and his PhD from Chicago in 1995, and in between worked
              for Roll and Ross Asset Management. He has written for academics
              and practitioners on a variety of financial topics, and has served
              as editor and associate editor at finance journals. At Wharton he
              teaches Capital Markets and co-teaches Strategic Equity Finance.
            </p>
          </div>
        </div>
      </div>
      <div className="team-context-section">
        <h1 className="team-context-title">Student Teams</h1>
        <div className="student-team-context-content">
          <div className="team-context-content-para">
            <img src={CodingIcon} alt="icon" />
            <div className="content-description">
              <h2>Coding</h2>
              <p className="team-context-description">
                We learn how to code in Java and use these skills to develop the
                frontend and backend of Finiverse.
              </p>
            </div>
          </div>
          <div className="team-context-content-para">
            <img src={ProductIcon} alt="icon" />
            <div className="content-description">
              <h2>Product Design</h2>
              <p className="team-context-description">
                We conduct user research and testing sessions to create design
                mockups of new features and functionalities.
              </p>
            </div>
          </div>
          <div className="team-context-content-para">
            <img src={DataIcon} alt="icon" />
            <div className="content-description">
              <h2>Data Research</h2>
              <p className="team-context-description">
                We conduct research and collect data to create equations for
                calculations in the app and ensure that the data displayed is
                grounded in reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
}

export default OurTeam;
