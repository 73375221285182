import { FC, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state";
import OrangeStepButtons from "../../../components/OrangeStepButtons";
import {
  setCurrentYear,
  updateLoanAccumulationStepIndex,
  updateLoanBalanceSoFar,
} from "../../financing-options-slice";
import { scrollToElement } from "../../../utils";

const Style = styled.div`
  .buttons-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
  }

  .orange-text {
    color: ${theme.colors.secondary};
  }
`;

export const HowLoansWork: FC = () => {
  const dispatch = useDispatch();
  const stepIndex = useSelector(
    (state: RootState) => state.financingOptions.loanAccumulationStepIndex,
  );

  useEffect(() => {
    dispatch(
      updateLoanBalanceSoFar({
        total: 0,
        federalSubsidized: 0,
        federalUnsubsidized: 0,
        private: 0,
      }),
    );
    dispatch(setCurrentYear(1));
  }, [dispatch]);

  return (
    <Style className="outlined-explanation-section">
      <h3>How a Loan Works</h3>
      <p>
        You take out loans when you don’t have enough money to purchase
        something right now but would like to pay it back later. <br />
        <br /> Let’s say you want to buy a pair of shoes for $150 but you only
        have $50 right now. You decide to take out a loan from your friend for{" "}
        <b>$100</b> (this is your <b className="orange-text">loan principal</b>)
        so that you have enough money to pay for the shoes. <br />
        <br /> Usually, paying back a loan includes{" "}
        <b className="orange-text">interest</b>. This is extra money that you
        have to pay back on top of the original $100 principal that you
        borrowed. Let’s say this loan has a <b>5%</b> annual interest rate. That
        means that a year after you take out that $100 loan from your friend,
        you will actually owe him $100 + (0.05 x $100) = $100 + $5 = <b>$105</b>{" "}
        in total (this is your <b className="orange-text">loan balance</b>).
      </p>
      <div className="buttons-section">
        <OrangeStepButtons
          stepNumber={stepIndex}
          totalSteps={10}
          restartButtonText="Back to Summary"
          nextButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(2));
            scrollToElement("loan-accumulation-heading");
          }}
          backButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(0));
            scrollToElement("loan-accumulation-heading");
          }}
          restartButtonAction={() => {
            dispatch(updateLoanAccumulationStepIndex(0));
            dispatch(setCurrentYear(1));
            dispatch(
              updateLoanBalanceSoFar({
                total: 0,
                federalSubsidized: 0,
                federalUnsubsidized: 0,
                private: 0,
              }),
            );
            scrollToElement("loan-accumulation-heading");
          }}
        />
      </div>
    </Style>
  );
};
