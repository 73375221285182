import Tabs, { TabsProps } from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import { styled } from "@mui/material/styles";
import theme from "../theme";
import { FC, ReactNode } from "react";

interface ThemedTabsProps {
  className?: string;
  ariaLabel?: string;
  tabValue: string;
  setSelectedTab: (event: React.SyntheticEvent, newValue: string) => void;
  children?: ReactNode;
}

const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-scrollButtons": {
    ".MuiTabs-scrollButtons": {
      fill: theme.colors.secondary,
    },
  },
});

export const StyledTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  color: theme.colors.secondary,
  fontFamily: "Karla",
  fontSize: "1em",
  fontWeight: 700,
  padding: "8px 16px",
  minWidth: "15%",
  "&.Mui-selected": {
    color: theme.colors.neutrals.white,
    backgroundColor: theme.colors.secondary,
    border: `2px solid ${theme.colors.secondary}`,
    borderRadius: `6px 6px 0px 0px`,
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.colors.secondary,
  },
}));

const ThemedTabs: FC<ThemedTabsProps> = ({
  className,
  tabValue,
  setSelectedTab,
  ariaLabel,
  children,
}) => {
  return (
    <StyledTabs
      className={className}
      value={tabValue}
      onChange={setSelectedTab}
      aria-label={ariaLabel}
    >
      {children}
    </StyledTabs>
  );
};

export default ThemedTabs;
