import styled from "styled-components";
import theme from "../theme";
import { moneyFormat } from "../simulations/utils";
import FinancingOptionsInputs from "./FinancingOptionInputs";
import LoanAccumulationSection from "./loan-accumulation-section/LoanAccumulationSection";
import { LoanFigureComponent } from "./loan-figure-component";
import { useSelector } from "react-redux";
import { RootState } from "../state";
import EquationLayout from "../components/EquationLayout";

interface ResultsProps {
  selectedCollegeId: string;
}

const Style = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
  padding: 1.5em;
  border: 2px solid ${theme.colors.secondary};
  border-radius: 0px 0px 6px 6px;

  .graph {
    width: 700px;
    height: 80px;
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      width: inherit;
    }
  }

  .stats-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 16px;

    @media (max-width: ${theme.breakpoints.small}px) {
      display: none;
    }
  }

  .stats-container-mobile {
    display: none;

    @media (max-width: ${theme.breakpoints.small}px) {
      margin-top: 24px;
      display: block;
    }
  }

  .stats-container:nth-child(1) {
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .math-symbol {
    color: ${theme.colors.neutrals.mediumGray};
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
    width: 6%;
  }

  .alert-container {
    display: flex;
    gap: 12px;
    align-items: flex-start;

    .alert-icon {
      width: 36px;
      @media (max-width: ${theme.breakpoints.xlarge}px) {
        display: none;
      }
    }
  }

  .input-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    padding-top: 24px;

    .user-input {
      display: flex;
      gap: 12px;
      align-items: center;
    }

    .calculate-button {
      padding: 12px;
    }

    .input-title {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  @media (max-width: ${theme.breakpoints.small}px) {
    // width: 90%;
    padding: 0;
    width: inherit;
    border: 0;
  }

  @media (min-width: ${theme.breakpoints.medium}px) {
    width: 100%;
  }
`;

export const StudentLoansResults: React.FC<ResultsProps> = ({
  selectedCollegeId,
}) => {
  const { currentCollegeFinancialSources } = useSelector(
    (state: RootState) => state.financingOptions,
  );

  // Use for mobile equation
  const addends = [
    {
      label: "Annual sticker price",
      value: currentCollegeFinancialSources
        ? currentCollegeFinancialSources.totalAnnualCost
        : 0,
      tooltipText:
        "The estimated total price of your selected college over 4 years",
    },
    {
      label: "Institutional aid",
      value: currentCollegeFinancialSources
        ? currentCollegeFinancialSources.institutionalAid
        : 0,
      tooltipText:
        "How much estimated financial aid you could receive from the school",
    },
    {
      label: "Federal aid",
      value: currentCollegeFinancialSources
        ? currentCollegeFinancialSources.federalAid
        : 0,
      tooltipText:
        "The estimated amount you would receive from the federal government, including Pell Grants",
    },
    {
      label: "Other govt. aid",
      value: currentCollegeFinancialSources
        ? currentCollegeFinancialSources.stateAndLocalAid
        : 0,
      tooltipText:
        "The average amount of aid you might receive from state and local governmental sources",
    },
  ];

  const sum = {
    label: "Annual net price",
    value: currentCollegeFinancialSources
      ? currentCollegeFinancialSources.annualNetPrice
      : 0,
    tooltipText:
      "The estimated amount you'll actually need to pay over 4 years",
  };

  return (
    <Style>
      <div className="stats-container">
        <LoanFigureComponent
          className="equation-stats"
          label="Annual net price"
          value={
            "$" +
            moneyFormat(
              currentCollegeFinancialSources
                ? currentCollegeFinancialSources.annualNetPrice
                : 0,
            )
          }
          textColor={theme.colors.primary}
          tooltipText="The estimated amount you'll actually need to pay over 4 years"
          width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 25}
        />

        <div className="math-symbol">=</div>

        <LoanFigureComponent
          className="equation-stats"
          label="Annual sticker price"
          value={
            "$" +
            moneyFormat(
              currentCollegeFinancialSources
                ? currentCollegeFinancialSources.totalAnnualCost
                : 0,
            )
          }
          textColor={theme.colors.neutrals.mediumGray}
          tooltipText="The estimated total price of your selected college over 4 years"
          width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 30}
        />

        <div className="math-symbol">-</div>

        <LoanFigureComponent
          className="equation-stats"
          label="Institutional aid"
          value={
            "$" +
            moneyFormat(
              currentCollegeFinancialSources
                ? currentCollegeFinancialSources.institutionalAid
                : 0,
            )
          }
          textColor={theme.colors.neutrals.mediumGray}
          tooltipText="How much estimated financial aid you could receive from the school"
          width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 25}
        />

        <div className="math-symbol">-</div>

        {/* TODO: replace this with Pell Grant amount */}
        <LoanFigureComponent
          className="equation-stats"
          label="Federal aid"
          value={
            "$" +
            moneyFormat(
              currentCollegeFinancialSources
                ? currentCollegeFinancialSources.federalAid
                : 0,
            )
          }
          textColor={theme.colors.neutrals.mediumGray}
          tooltipText="The estimated amount you would receive from the federal government, including Pell Grants"
          width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 25}
        />

        <div className="math-symbol">-</div>

        <LoanFigureComponent
          className="equation-stats addend"
          label="Other govt. aid"
          value={
            "$" +
            moneyFormat(
              currentCollegeFinancialSources
                ? currentCollegeFinancialSources.stateAndLocalAid
                : 0,
            )
          }
          textColor={theme.colors.neutrals.mediumGray}
          tooltipText="The average amount of aid you might receive from state and local governmental sources"
          width={window.innerWidth < theme.breakpoints.xsmall ? 100 : 25}
        />
      </div>

      <EquationLayout
        className="stats-container-mobile"
        addends={addends}
        sum={sum}
      />

      <FinancingOptionsInputs selectedCollegeId={selectedCollegeId} />

      <LoanAccumulationSection />
    </Style>
  );
};

export default StudentLoansResults;
