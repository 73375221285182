import { FC } from "react";
import styled from "styled-components";
import theme from "../theme";
import { CollegeCostsResults, CollegeFinancialSources } from "../types";
import SimpleBarGraph from "../components/graphs/HorizontalSimpleBarGraph";
import { costCategoryLegendMap, financialSourceLegendMap } from "../constants";
import { moneyFormat } from "../simulations/utils";
import HorizontalSimpleBarGraph from "../components/graphs/HorizontalSimpleBarGraph";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  .annual-title {
    border: 2px ${theme.colors.secondary} solid;
    text-align: center;
    max-width: fit-content;
    border-radius: 5px;
    padding: 12px;
  }

  .annual-title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .graph-title {
    margin-bottom: 24px;
  }

  .graph {
    width: 100%;
    height: 70px;
  }

  .graph-labels {
    display: flex;
    justify-content: space-between;

    .graph-label {
      display: flex;
      gap: 8px;
    }
  }

  .square {
    height: 12px;
    width: 12px;
  }

  .federalAid {
    background: ${theme.colors.graphColors.lightOrange};
  }

  .tuition,
  .stateAndLocalAid {
    background: ${theme.colors.graphColors.turquoise};
  }

  .fees,
  .familyContribution {
    background: ${theme.colors.graphColors.lavender};
  }

  .booksAndSupplies,
  .loanAmount {
    background: ${theme.colors.creamOrange};
  }

  .roomAndBoard,
  .other {
    background: ${theme.colors.graphColors.darkPurple};
  }

  .personalExpenses,
  .institutionalAid {
    background: ${theme.colors.graphColors.redOrange};
  }
`;
interface CollegeCostsSummaryProps {
  totalCost: string;
  results: CollegeCostsResults;
  financialSources: CollegeFinancialSources;
}
const CollegeCostSummary: FC<CollegeCostsSummaryProps> = ({
  totalCost,
  results,
  financialSources,
}) => {
  return (
    <Style>
      <div className="annual-title-container">
        <div className="summary-card-title annual-title">
          Annual Cost of Attendance : ${totalCost}
        </div>
      </div>

      <div className="graph-container">
        <div className="summary-card-title graph-title">
          Breakdown by Cost Category
        </div>
        <div className="graph-labels">
          {Object.entries(costCategoryLegendMap).map(([key, value]) => (
            <div className="graph-label">
              <div className={`square ${key}`}></div>
              {value}: $
              {moneyFormat(results[key as keyof CollegeCostsResults] as number)}
            </div>
          ))}
        </div>
        <div className="graph">
          <HorizontalSimpleBarGraph
            data={[results]}
            layout="horizontal"
            xAxisKey="schoolName"
            yAxisKeys={[
              "tuition",
              "fees",
              "booksAndSupplies",
              "roomAndBoard",
              "personalExpenses",
            ]}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel=""
            legendWidth={140}
            colors={[
              theme.colors.graphColors.turquoise,
              theme.colors.graphColors.lavender,
              theme.colors.creamOrange,
              theme.colors.graphColors.darkPurple,
              theme.colors.graphColors.redOrange,
            ]}
          />
        </div>
      </div>

      <div className="graph-container">
        <div className="summary-card-title graph-title">
          Breakdown by Financial Source
        </div>
        <div className="graph-labels">
          {Object.entries(financialSourceLegendMap).map(([key, value]) => (
            <div className="graph-label">
              <div className={`square ${key}`}></div>
              {value}: ${financialSources[key as keyof CollegeFinancialSources]}
            </div>
          ))}
        </div>
        <div className="graph">
          <SimpleBarGraph
            data={[financialSources]}
            layout="horizontal"
            xAxisKey="schoolName"
            yAxisKeys={[
              "federalAid",
              "stateAndLocalAid",
              "institutionalAid",
              "familyContribution",
              "other",
              "loanAmount",
            ]}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel=""
            legendWidth={180}
            colors={[
              theme.colors.graphColors.lightOrange,
              theme.colors.graphColors.turquoise,
              theme.colors.graphColors.redOrange,
              theme.colors.graphColors.lavender,
              theme.colors.graphColors.darkPurple,
              theme.colors.creamOrange,
            ]}
          />
        </div>
      </div>
    </Style>
  );
};

export default CollegeCostSummary;
