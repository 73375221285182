import styled from "styled-components";
import InfoIcon from "../assets/icons/info.svg";

const Style = styled.div`
  width: 100%;
`;

const EstimateNote = () => {
  return (
    <Style>
      <div className="info-alert">
        <img src={InfoIcon} alt="information icon" />
        <p>
          Note:{" "}
          <b>
            All numbers displayed in Finiverse are <u>estimates</u>.
          </b>
        </p>
      </div>
    </Style>
  );
};

export default EstimateNote;
