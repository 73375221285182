import { FormControlLabel, Radio } from "@mui/material";
import theme from "../theme";
import styled from "styled-components";

const Style = styled.div`
  .label {
    font-family: "Karla", sans-serif;
    margin: 0px;
  }
`;

interface RadioInputProps {
  value: string;
  label: string;
}

const RadioInput: React.FC<RadioInputProps> = ({ value, label }) => {
  return (
    <Style>
      <FormControlLabel
        value={value}
        control={
          <Radio
            sx={{
              color: theme.colors.secondary,
              "&.Mui-checked": {
                color: theme.colors.secondary,
              },
            }}
          />
        }
        label={<p className="label">{label}</p>}
      />
    </Style>
  );
};

export default RadioInput;
