import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import CollegeCostsPage from "./college-costs/CollegeCostsPage";
import Page from "./components/Page";
import { StudentLoansPage } from "./student-loans/StudentLoansPage";
import Home from "./home/Home";
import DollarIcon from "./assets/icons/dollar-circle-blue.svg";
import CreditCardIcon from "./assets/icons/credit-card-blue.svg";
import LightningIcon from "./assets/icons/lightning-blue.svg";
import styled from "styled-components";
import theme from "./theme";
import SimulationPage from "./simulations/SimulationPage";
import AboutUs from "./about-us-page/AboutUs";
import OurMission from "./about-us-page/OurMission";
import OurStory from "./about-us-page/OurStory";
import OurTeam from "./about-us-page/OurTeam";
import ContactUs from "./about-us-page/ContactUs";
import QuoteLeft from "./assets/icons/quotation-left.svg";
import QuoteRight from "./assets/icons/quotation-right.svg";
import { useDispatch } from "react-redux";
import { getAllDropdownOptions } from "./college-costs/college-costs-slice";
import { College, incomeBrackets } from "./types";
import DownloadPage from "./downloadable-summary/DownloadPage";
import { getAllJobDropdownOptions } from "./student-loans/financing-options-slice";
import News from "./about-us-page/News";

const Style = styled.div`
  .text-callout {
    text-align: left;
    background: ${theme.colors.creamOrange};
    padding: 24px;
    border-radius: 6px;
  }

  .info-alert {
    display: flex;
    align-items: center;
    gap: 8px;
    text-align: left;
  }

  .instruction-text {
    text-align: left;
    padding-left: 16px;
    border-left: 5px solid ${theme.colors.secondary};
    line-height: 130%;
  }

  .help-link {
    text-decoration: underline;
    color: ${theme.colors.secondary};
    cursor: pointer;
  }

  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 10rem;

    .empty-state-text {
      text-align: center;
      color: ${theme.colors.neutrals.darkGray} !important;
    }
  }
`;

function App() {
  //initialize all college
  const dispatch = useDispatch();
  // TODO: remove this when real data is connected
  let dropdownData = {
    colleges: [{ collegeId: "", name: "" }],
    programsOfStudy: ["Bachelor's Degree"],
    householdIncomeBrackets: incomeBrackets,
  };

  fetch(`${process.env.REACT_APP_API_URL}/all-colleges`, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((colleges: College[]) => {
      dropdownData = { ...dropdownData, colleges: colleges };
      dispatch(getAllDropdownOptions(dropdownData));
    });

  //initialize all jobs
  fetch(`${process.env.REACT_APP_API_URL}/all-careers`)
    .then((response) => response.json())
    .then((responseJSON) => {
      dispatch(
        getAllJobDropdownOptions({
          majors: [],
          jobs: responseJSON,
        }),
      );
    });

  return (
    <Style>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/college-costs"
              element={
                <Page
                  title={"How much does college cost?"}
                  pageNumber={0}
                  titleIcon={DollarIcon}
                  children={<CollegeCostsPage />}
                />
              }
            />
            <Route
              path="/financing-options"
              element={
                <Page
                  title={"How do I pay for college?"}
                  titleIcon={CreditCardIcon}
                  pageNumber={1}
                  children={<StudentLoansPage />}
                />
              }
            />
            <Route
              path="/risk-simulation"
              element={
                <Page
                  title={"What are the risks to consider?"}
                  titleIcon={LightningIcon}
                  pageNumber={2}
                  children={<SimulationPage />}
                />
              }
            />

            <Route
              path="/our-mission"
              element={
                <AboutUs
                  pageNumber={0}
                  title={"For Students, By Students"}
                  children={<OurMission />}
                  decoLeft={QuoteLeft}
                  decoRight={QuoteRight}
                />
              }
            />
            <Route
              path="/our-story"
              element={
                <AboutUs
                  pageNumber={0}
                  title={"Our Story"}
                  children={<OurStory />}
                />
              }
            />
            <Route
              path="/our-team"
              element={
                <AboutUs
                  pageNumber={0}
                  title={"Our Team"}
                  children={<OurTeam />}
                />
              }
            />
            <Route
              path="/news"
              element={
                <AboutUs
                  pageNumber={0}
                  title={"In the News"}
                  children={<News />}
                />
              }
            />
            <Route
              path="/contact-us"
              element={
                <AboutUs
                  pageNumber={0}
                  title={"Contact Us"}
                  children={<ContactUs />}
                />
              }
            />
            <Route path="/summary" element={<DownloadPage />} />
          </Routes>
        </Router>
      </div>
    </Style>
  );
}

export default App;
