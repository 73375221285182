import { FC, useEffect } from "react";
import styled from "styled-components";
import TextCallout from "../../../components/TextCallout";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentYear,
  updateLoanAccumulationStepIndex,
  updateLoanBalanceSoFar,
} from "../../financing-options-slice";
import { LoanFigureComponent } from "../../loan-figure-component";
import theme from "../../../theme";
import { moneyFormat } from "../../../simulations/utils";
import { scrollToElement } from "../../../utils";
import { RootState } from "../../../state";

interface LoanAccumulationSummaryProps {}

const SummaryStyle = styled.div`
  .buttons-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
  }
  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .walk-me-through-button {
      display: block;
    }
  }

  .figure-column {
    width: 33%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 6px;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      width: 100%;
      gap: 0;
      padding: 5px;
    }
  }
`;

export const LoanAccumulationSummary: FC<LoanAccumulationSummaryProps> = () => {
  const dispatch = useDispatch();
  const { currentFinancingOptionsResult } = useSelector(
    (state: RootState) => state.financingOptions,
  );

  useEffect(() => {
    dispatch(
      updateLoanBalanceSoFar({
        total: 0,
        federalSubsidized: 0,
        federalUnsubsidized: 0,
        private: 0,
      }),
    );
    dispatch(setCurrentYear(1));
  }, [dispatch]);

  return (
    // TODO: update with real numbers
    <SummaryStyle className="outlined-explanation-section">
      <div className="rates-section">
        <div className="figure-column">
          <b className="purple-text">Federal subsidized loans</b>
          <LoanFigureComponent
            label="LOAN BALANCE"
            value={
              "$" +
              moneyFormat(
                currentFinancingOptionsResult
                  ? currentFinancingOptionsResult.totalSubsidized
                  : 0,
              )
            }
            footnote="Interest rate: 0% while you’re in college, 5.5% after you graduate."
            textColor={theme.colors.graphColors.eggplantPurple}
          />
        </div>

        <div className="figure-column">
          <b className="green-text">Federal unsubsidized loans</b>
          <LoanFigureComponent
            label="LOAN BALANCE"
            value={
              "$" +
              moneyFormat(
                currentFinancingOptionsResult
                  ? currentFinancingOptionsResult.totalUnsubsidized
                  : 0,
              )
            }
            footnote="Interest rate: 5.5%"
            textColor={theme.colors.graphColors.mediumGreen}
          />
        </div>
        <div className="figure-column">
          <b className="blue-text">Private loans</b>
          <LoanFigureComponent
            label="LOAN BALANCE"
            value={
              "$" +
              moneyFormat(
                currentFinancingOptionsResult
                  ? currentFinancingOptionsResult.totalPrivate
                  : 0,
              )
            }
            footnote="Interest rate: 8%"
            textColor={theme.colors.graphColors.indigo}
          />
        </div>
      </div>
      <p>
        This is an estimate of how much you could owe (including interest) by
        the end of college.
        <br />
        <br />
        Remember, <b>interest will be added to your balance every year</b> until
        you pay off all of your student loans.
      </p>
      <TextCallout
        text={
          <>
            If these numbers are uncomfortably high, these are some options you
            could consider before starting college in order to reduce your
            student loan balance:
            <ul>
              <li>
                Consider going to a cheaper college (like community college)
                first and/or transferring after 1-2 years.
              </li>
              <li>
                Take advantage of scholarships and grants before looking at
                loans.
              </li>
              <li>
                Test out of college courses in high school through AP or IB
                courses so that you can pay for fewer college courses and/or
                graduate early.
              </li>
            </ul>
          </>
        }
      />
      <div className="buttons-section walk-me-through-button">
        <Button
          buttonText={"Walk me through it!"}
          handleOnClick={() => {
            dispatch(updateLoanAccumulationStepIndex(1));
            scrollToElement("loan-accumulation-heading");
          }}
        />
      </div>
    </SummaryStyle>
  );
};
