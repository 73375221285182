import * as d3 from "d3";
import { ResultsSummaryInputs, SingleSimulationResults } from "../types";

let formatter = Intl.NumberFormat("en", { notation: "compact" });

export const collegeEventContent = (selectedInputs: ResultsSummaryInputs) => {
  return {
    message: (
      <>
        Studied <b>{selectedInputs.school.major || "Computer Science"}</b> at{" "}
        <b>
          {selectedInputs.school.college
            ? selectedInputs.school.college.name
            : "University of Pennsylvania"}
        </b>
      </>
    ),
    color: "green",
  };
};

export const graduationEventContent = (results: SingleSimulationResults) => {
  return {
    message: (
      <>
        {results.collegePeriod.graduated ? "Graduated in" : "Dropped out after"}{" "}
        <b>{results.collegePeriod.yearsInSchool}</b> years
      </>
    ),
    color: results.collegePeriod.graduated ? "green" : "orange",
  };
};

export const jobEventContent = (
  selectedInputs: ResultsSummaryInputs,
  results: SingleSimulationResults,
) => {
  return [
    {
      message: (
        <>
          Worked at a job related to{" "}
          <b>{selectedInputs.school.major || "Degree"}</b> for{" "}
          <b>{results.jobPeriod.yearsEmployedInJobRelatedToDegree}</b> years
        </>
      ),
      color: "green",
    },
    {
      message: (
        <>
          Worked at a job related to{" "}
          <b>{selectedInputs.school.major || "Degree"}</b> for{" "}
          <b>{results.jobPeriod.yearsEmployedInJobRelatedToDegree}</b> years
        </>
      ),
      color: "green",
    },
    {
      message: (
        <>
          Were unemployed for <b>{results.jobPeriod.yearsUnemployed}</b> years
        </>
      ),
      color: "orange",
    },
  ];
};

export const timelineEventContent = (
  selectedInputs: ResultsSummaryInputs,
  results: SingleSimulationResults,
) => {
  const finalTimeline = [
    collegeEventContent(selectedInputs),
    graduationEventContent(results),
  ].concat(jobEventContent(selectedInputs, results));
  console.log(finalTimeline);
  return finalTimeline;
};

//handle commas every three 0s
export const moneyFormat = (num: number) => {
  const text = num.toString();
  let res = "";
  let counter = 0;
  for (let i = text.length - 1; i >= 0; i--) {
    counter++;
    res = text[i] + res;
    if (counter % 3 === 0 && i !== 0) {
      res = "," + res;
    }
  }
  return res;
};

// Calculate bins for total dataset
export const calculateBins = (min: number, max: number, numOfBins: number) => {
  const histGenerator = d3
    .bin()
    .domain([min, max]) // Set the domain to cover the entire intervall [0;]
    .thresholds(numOfBins - 1); // number of thresholds; this will create 19+1 bins

  return histGenerator;
};

// Put data into calculated bins
export const binData = (
  histGenerator: d3.HistogramGeneratorNumber<number, number>,
  dataToBin: number[],
) => {
  const binnedData = histGenerator(dataToBin);

  return binnedData;
};

// Calculate likelihood for each simulation's result
export const formatHistogramData = (
  numberOfSimulations: number,
  dataToFormat: d3.Bin<number, number>[],
) => {
  const formattedData = dataToFormat.map((bin) => {
    return {
      bin: bin,
      amount: `${
        bin.x0 && bin.x1
          ? `${formatter.format(bin.x0)} - ${formatter.format(bin.x1)}`
          : ""
      }`,
      likelihood: bin.length / numberOfSimulations,
    };
  });

  return formattedData;
};

// Return complete formatted histogram data for selected metric
export const convertAndFormatHistogramData = (
  metricData: number[],
  simulationNumber: number,
) => {
  const histGenerator = calculateBins(
    Math.min(...metricData),
    Math.max(...metricData),
    10,
  );

  const binnedData = binData(
    histGenerator,
    metricData.slice(0, simulationNumber),
  );

  const formattedData = formatHistogramData(simulationNumber, binnedData);

  return formattedData;
};
