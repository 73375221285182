import styled from "styled-components";
import { useState } from "react";
import RadioButtonGroup from "../components/RadioButtonGroup";
import theme from "../theme";
import { Divider } from "@mui/material";

const Style = styled.div`
  .stylebox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    line-height: 1;
    font-size: 20px;
  }

  h2 {
    font-weight: normal;
    font-size: 20px;
  }
  li {
    font-weight: normal;
    text-align: left;
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    border-bottom: 1px solid;
  }
  b {
    font-size: 20px;
  }

  .title {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
  }
  .text1 {
    text-align: left;
    margin-top: 0px;
  }
  .text2 {
    text-align: left;
  }
  .text3 {
    text-align: left;
    margin-bottom: 0px;
  }
  .bulletPoint {
    font-size: 12px;
    font-size: 20px;
  }
  .subTitle {
    font-style: italic;
    border-left: 5px solid ${theme.colors.creamOrange};
    margin-bottom: 0px;
    width: 80%;
    height: 30px;
    padding-left: 8px;
  }
  .numberedPoint {
    margin-bottom: 30px;
    font-size: 20px;
  }
  .contentbox {
    background-color: ${theme.colors.creamOrange};
    padding: 20px;
    width: 80%;
    border-radius: 5px;
    line-height: 1.3em;
  }

  .muiDivider {
    background-color: ${theme.colors.primary};
    width: 80%;
    height: 1.5px;
    border-bottom-width: none;
  }

  .lowertextbox {
    width: 80%;
    justify-content: flex-start;
  }
  .responseBox {
    width: 80%;
    line-height: 1.3em;
  }
`;

function parentPlusEligibilityObject(eligibilityValue: number) {
  const responseBoxQualities = {
    color: "",
    unboldText: "",
    boldText: "",
    padding: "",
    width: "",
  };
  if (eligibilityValue === 2) {
    responseBoxQualities["color"] = `${theme.colors.lightGreen}`;
    responseBoxQualities["boldText"] =
      "You are eligible for Parent PLUS loans! ";
    responseBoxQualities["unboldText"] =
      "The maximum PLUS loan amount you can borrow equals the cost of attendance at the school that you will attend minus any other financial assistance you receive.";
    responseBoxQualities["padding"] = "30px";
  }
  if (eligibilityValue === 1) {
    responseBoxQualities["color"] = `${theme.colors.lightRed}`;
    responseBoxQualities["boldText"] =
      "You are currently not eligible for Parent PLUS loans. ";
    responseBoxQualities["unboldText"] =
      "Please check with your parent if you’re not sure that you meet all of the above criteria.";
    responseBoxQualities["padding"] = "30px";
  }
  if (eligibilityValue === 0) {
    responseBoxQualities["color"] = "";
    responseBoxQualities["boldText"] = "";
    responseBoxQualities["unboldText"] = "";
    responseBoxQualities["padding"] = "";
  }
  const Style2 = styled.div`
    .response {
      background: ${responseBoxQualities["color"]};
      padding: ${responseBoxQualities["padding"]};
      border-radius: 5px;
    }
  `;

  return (
    <Style2>
      <div
        className="response"
        style={{
          background: responseBoxQualities["color"],
          padding: responseBoxQualities["padding"],
        }}
      >
        <b>{responseBoxQualities["boldText"]}</b>
        {responseBoxQualities["unboldText"]}
      </div>
    </Style2>
  );
}

interface CollegeCostsSAIProps {
  showOverlay: boolean;
  hideOverlay: () => void;
}

const CollegeCostsParentPlus: React.FC<CollegeCostsSAIProps> = ({
  showOverlay,
  hideOverlay,
}) => {
  const [eligibilityValue, changeEligibilityValue] = useState(0);
  const [radioButtonValue, SetRadioButtonValue] = useState("");

  return (
    <Style>
      <div className="stylebox">
        <h2 className="title">Parent Plus Loans</h2>
        <div className="contentbox">
          <h2 className="text1">
            Parent PLUS loans are a type of federal loan that parents can get to
            help pay for their child's college. They're beneficial because:
          </h2>
          <ul>
            <li className="bulletPoint">
              They offer a way for parents to cover college costs when other
              finnancial aid isn't enough.
            </li>
            <li className="bulletPoint">
              They usually have <b>lower interest rates</b> than private loans
              and can cover various expenses.
            </li>
          </ul>
          <h2 className="text2">
            Just remember, <b>your parents</b> are responsible for repaying
            them, so it's important to think carefully before taking out PLUS
            loans.
          </h2>
          <h2 className="text3">
            To read more about Parent Plus Loans, click here{" "}
            <a href="https://studentaid.gov/understand-aid/types/loans/plus/parent">
              here
            </a>
            .
          </h2>
        </div>
        <h2 className="subTitle">
          You are only eligible for a Parent PLUS loan if you meet{" "}
          <b>
            <u>ALL</u>
          </b>{" "}
          of the following criteria:
        </h2>
        <div className="lowertextbox">
          <ol>
            <li className="numberedPoint">
              You are an undergraduate student that is enrolled at least
              half-time.{" "}
            </li>
            <li className="numberedPoint">
              You have a biological or adoptive parent.
            </li>
            <li className="numberedPoint">
              Your parent does not have adverse credit history.
            </li>
            <li className="numberedPoint">
              You and your parent are US citizens or eligible non-citizens with
              a Social Security number.{" "}
            </li>
            <li className="numberedPoint">
              You and your parent meet the{" "}
              <b>
                <a href="https://studentaid.gov/understand-aid/eligibility/requirements">
                  basic eligibility requirements
                </a>
              </b>{" "}
              for federal student aid.
            </li>
          </ol>
        </div>
        <Divider className="muiDivider" variant="middle" />

        <RadioButtonGroup
          fieldValue={radioButtonValue}
          buttonOptions={[
            "I meet all of the above criteria",
            "I don't meet all of the above criteria / I'm not sure if I meet all of the above criteria",
          ]}
          handleChange={(e) => {
            SetRadioButtonValue(e.target.value);
            if (e.target.value === "I meet all of the above criteria") {
              changeEligibilityValue(2);
            }
            if (
              e.target.value ===
              "I don't meet all of the above criteria / I'm not sure if I meet all of the above criteria"
            ) {
              changeEligibilityValue(1);
            }
          }}
        />
        <div className="responseBox">
          {parentPlusEligibilityObject(eligibilityValue)}
        </div>
      </div>
    </Style>
  );
};

export default CollegeCostsParentPlus;
