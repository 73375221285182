import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../components/Button";
import { useNavigate } from "react-router-dom";
import rightArrow from "../assets/icons/arrow-right-blue.svg";
import leftArrow from "../assets/icons/arrow-left-blue.svg";
import { CollegeCostsResults, CollegeFinancialSources } from "../types";
import theme from "../theme";
import TextCallout from "../components/TextCallout";
import { PopupType } from "../types";
import {
  setCollegeFinancialSources,
  updateFinancingOptionsBySchoolId,
} from "../student-loans/financing-options-slice";
import { RootState } from "../state";
import { useDispatch, useSelector } from "react-redux";
import { financialSourceLegendMap } from "../constants";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useForm } from "react-hook-form";
import InputOption from "../components/InputOption";
import HorizontalDetailedBarGraph from "../components/graphs/HorizontalDetailedBarGraph";
// import EstimateNote from "../components/EstimateNote";
// import penIcon from "../assets/icons/pen.svg";
import NetPriceBarGraph from "../components/graphs/NetPriceBarGraph";

interface ResultsProps {
  results: CollegeCostsResults[];
  showOverlay: (popupContent?: PopupType) => void;
}

const Style = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    margin-top: 20px;
  }

  .tabs {
    margin-bottom: 20px;
  }

  .college-cost-container {
    display: flex;
    background: rgba(175, 210, 233, 0.5);
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;

    .value-container {
      display: flex;
      flex-direction: column;
    }
  }

  .spaced-out {
    justify-content: space-around;
  }

  .centered {
    justify-content: center;
  }

  .footer {
    padding-top: 24px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .hidden-button {
      visibility: hidden;
    }

    .visible-button {
      visibility: visible;
    }
  }

  h2 {
    margin-block-start: 0;
  }

  .dropdown-container {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .graph {
    width: 65vw;
    height: 700px;
    position: relative;
    @media (max-width: ${theme.breakpoints.medium}px) {
      width: 100%;
      height: 110vh;
    }
  }

  .inputs-container {
    padding: 24px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
  }

  .input-fields {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      display: block;
      margin-block-start: 1em;
      margin-block-end: 0;
      .input-option {
        justify-content: space-between;
      }
      .option {
        width: 150px;
      }
      button {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  .inputs-container-c {
    padding: 5px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    display: none;
    flex-direction: row;
  }

  .add-school-button {
    position: relative;
    top: 83px;
    @media (max-width: ${theme.breakpoints.xlarge}px) {
      top: 0px;
      width: 60vw;
    }
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      top: 0px;
      width: 90vw;
    }
  }

  .container-c-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1em;
    margin-right: 1em;

    Button {
      display: table-column-group;
      width: 40px;
      border: 0px;
      padding: 10px;
    }

    .editButton {
      margin: 0;
    }
  }

  .margin-bottom {
    margin-bottom: 24px;
  }

  .disclaimer-text {
    font-size: 16px;
    margin-bottom: 16px;
  }

  @media (max-width: ${theme.breakpoints.large}px) {
    margin-top: 0px;

    .footer {
      gap: 50px;
    }

    .disclaimer-text {
      margin-bottom: 36px;
    }
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .inputs-container-c {
      display: block;
    }
    .footer {
      gap: 50px;
    }
  }
`;

interface IFormInputs {
  studentAidIndexAmount: string;
  pellGrantAmount: string;
}

const mobileWidth = theme.breakpoints.xlarge;

const selectFinancingOptionsState = (state: RootState) =>
  state.financingOptions;

export const Results: React.FC<ResultsProps> = ({ results, showOverlay }) => {
  const dispatch = useDispatch();
  const form = useForm<IFormInputs>();
  let navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("0");
  const { financingOptions, collegeFinancialSources } = useSelector(
    selectFinancingOptionsState,
  );
  const showNetPriceResults = useSelector(
    (state: RootState) => state.collegeCosts.showNetPriceResults,
  );
  const stateOfResidence = useSelector(
    (state: RootState) => state.collegeCosts.selectedOptions.stateOfResidence,
  );

  const hasMissingCoefficient = collegeFinancialSources.filter(
    (school: CollegeFinancialSources) => !school.coefficientAvailable,
  );

  // TODO: added the selected state to pass down data
  // const selectedUSState = useSelector((state: RootState) => {
  //   return state.collegeCosts.selectedOptions.stateOfResidence;
  // });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let collegeIds: string[] = results.map((college) => college.collegeId);
    let familyContributions: string[] = financingOptions.map(
      (financingOption) => financingOption.familyContributionAmount,
    );
    let parentPlusLoanAmounts: string[] = financingOptions.map(
      (financingOption) => financingOption.parentPlusLoanAmount,
    );
    let otherAmounts: string[] = financingOptions.map(
      (financingOption) => financingOption.otherAmount,
    );

    if (
      collegeIds.length !== familyContributions.length ||
      collegeIds.length !== parentPlusLoanAmounts.length ||
      collegeIds.length !== otherAmounts.length
    ) {
      return;
    }

    let params = new URLSearchParams([
      ["stateOfResidence", stateOfResidence],
      [
        "studentAidIndex",
        (financingOptions.length > 0
          ? financingOptions[0].studentAidIndexAmount
          : "0"
        ).toString(),
      ],
      [
        "pellGrantAmount",
        (financingOptions.length > 0
          ? financingOptions[0].pellGrantAmount
          : "0"
        ).toString(),
      ],
    ]);

    collegeIds.map((id) => params.append("collegeIds", id.toString()));
    familyContributions.map((familyContribution) =>
      params.append("familyContributions", familyContribution.toString()),
    );
    parentPlusLoanAmounts.map((parentPlusLoanAmount) =>
      params.append("parentPlusLoanAmounts", parentPlusLoanAmount.toString()),
    );
    otherAmounts.map((otherAmount) =>
      params.append("otherAmounts", otherAmount.toString()),
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/calculate-college-financial-sources?` +
        params,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json",
        },
      },
    )
      .then((response) => response.json())
      .then((responseJSON) => {
        dispatch(setCollegeFinancialSources(responseJSON));
      });
  }, [results, financingOptions, dispatch, stateOfResidence]); // TODO: Make sure this doesn't change for other modifications here accidentally

  const graphViewOptions = [
    {
      name: "Annual sticker price",
      description: (
        <>
          When you go to a college’s website, they usually show the{" "}
          <b>sticker price</b>, which is the total annual cost of attendance
          displayed on a college’s website.{" "}
          <a
            href="https://bigfuture.collegeboard.org/pay-for-college/get-started/focus-on-net-price-not-sticker-price"
            style={{
              textDecorationLine: "underline",
              color: theme.colors.neutrals.black,
            }}
          >
            {" "}
            Learn more about sticker price versus net price.
          </a>
        </>
      ),
    },
    {
      name: "Annual net price",
      description: (
        <>
          But <b>over 85%</b> of students don’t actually pay the sticker price
          (Source:{" "}
          <a
            href="https://nces.ed.gov/programs/digest/d21/tables/dt21_331.20.asp"
            style={{
              textDecorationLine: "underline",
              color: theme.colors.neutrals.black,
            }}
          >
            NCES
          </a>
          ) - they pay the <b>net price!</b> This is the amount you actually
          need to pay each year after receiving federal and institutional
          grants.
        </>
      ),
    },
  ];

  //TODO: ADD BACK when doing mobile design for SAI
  // function showEdit() {
  //   var x = document.getElementById("editPanel");
  //   if (!x) return;
  //   x.style.display = x.style.display === "block" ? `none` : `block`;
  // }

  return (
    <Style>
      <div className="dropdown-container">
        <Tabs
          className="tabs"
          value={selectedTab}
          onChange={(event: React.SyntheticEvent, newValue: string) => {
            setSelectedTab(newValue);
          }}
          aria-label="college costs navigation tabs"
        >
          {graphViewOptions.map((view, id) => (
            <Tab label={view.name} value={id.toString()} />
          ))}
        </Tabs>
        <TextCallout
          text={graphViewOptions[parseInt(selectedTab)].description}
        />
        {/* TODO: Add back when redo the mobile responsive */}
        {/* {selectedTab === "1" && (
          <div className="inputs-container-c">
            {
              <div className="container-c-labels">
                <p>
                  <b>SAI </b> ${financingOptions.studentAidIndexAmount}
                </p>
                <p>
                  <b>Pell Grant </b> ${financingOptions.pellGrantAmount}
                </p>
                <Button
                  className="edit-button"
                  buttonText={""}
                  buttonLeftIcon={penIcon}
                  handleOnClick={showEdit}
                  bgColor={theme.colors.primary}
                  borderColor={theme.colors.primary}
                  textColor={theme.colors.neutrals.white}
                  height={"40px"}
                />
              </div>
            }
          </div>
        )} */}
        {selectedTab === "1" && (
          <div className="inputs-container">
            <p>
              To see the <b>net price</b> for each school, please calculate your{" "}
              <a
                href="https://studentaid.gov/help-center/answers/article/what-is-sai"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.neutrals.black,
                  fontWeight: "bold",
                }}
              >
                Student Aid Index (SAI)
              </a>
              . This determines how much aid you may receive from the school
              (institutional aid) and from the government (
              <a
                href="https://studentaid.gov/help-center/answers/article/federal-pell-grant-program"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.neutrals.black,
                }}
              >
                Pell Grant
              </a>
              ).{" "}
            </p>
            <div className="input-fields">
              <InputOption
                label="SAI"
                form={form}
                currentCollegeData={financingOptions[0]}
                tooltip={
                  <>
                    The Student Aid Index is used to calculate how much
                    <br /> financial aid you may receive based on your Free
                    Application
                    <br />
                    for Federal Student Aid (FAFSA) application.
                    <br />
                    SAI will replace the old Expected Family Contribution
                    <br />
                    calculation starting from the 2024-2025 school year.
                    <br />
                  </>
                }
                name="studentAidIndexAmount"
                saveInputValue={(newValue) => {
                  results.forEach((result) => {
                    dispatch(
                      updateFinancingOptionsBySchoolId({
                        collegeId: result.collegeId,
                        dataToUpdate: { studentAidIndexAmount: newValue },
                      }),
                    );
                  });
                }}
              />

              <InputOption
                label="Pell Grant"
                currentCollegeData={financingOptions[0]}
                form={form}
                tooltip={
                  <>
                    Money that the government provides to college students based
                    on their financial need.
                    <br /> Grants, unlike loans, do not need to be repaid.
                  </>
                }
                name="pellGrantAmount"
                saveInputValue={(newValue) =>
                  results.forEach((result) => {
                    dispatch(
                      updateFinancingOptionsBySchoolId({
                        collegeId: result.collegeId,
                        dataToUpdate: { pellGrantAmount: newValue },
                      }),
                    );
                  })
                }
              />
              <Button
                className="calculate-button"
                buttonText={"Calculate"}
                handleOnClick={() => showOverlay("EDIT_SAI")}
                bgColor={theme.colors.primary}
                borderColor={theme.colors.primary}
                textColor={theme.colors.neutrals.white}
                height={"40px"}
              />
            </div>
          </div>
        )}
      </div>

      {selectedTab === "0" ? (
        <div className="graph">
          <HorizontalDetailedBarGraph
            data={results}
            layout="horizontal"
            xAxisKey="schoolName"
            yAxisKeys={["totalAnnualCost"]}
            showYAxis={true}
            xAxisLabel=""
            yAxisLabel=""
            legendLabelMap={{ totalAnnualCost: "Sticker price" }}
            colors={[theme.colors.creamOrange]}
          />
        </div>
      ) : showNetPriceResults ? (
        <>
          <div className="instruction-text margin-bottom">
            Note: Finiverse is refining this calculation, which produces an{" "}
            <b>estimate</b> of your financial aid based on your SAI. Please
            confirm your SAI and financial aid amount with your chosen college
            before making any college financing decisions.
          </div>

          <div className="graph">
            <NetPriceBarGraph
              data={collegeFinancialSources}
              layout="horizontal"
              xAxisKey="schoolName"
              yAxisKeys={[
                "annualNetPrice",
                "federalAid",
                "stateAndLocalAid",
                "institutionalAid",
              ]}
              showYAxis={true}
              enableLabel={true}
              xAxisLabel=""
              yAxisLabel=""
              legendLabelMap={financialSourceLegendMap}
              legendWidth={155}
              colors={[
                theme.colors.primary,
                theme.colors.creamOrange,
                theme.colors.lightGreen,
                theme.colors.graphColors.lightLavender,
              ]}
            />
          </div>
          {hasMissingCoefficient.length > 0 && (
            <p className="disclaimer-text">
              <b>**</b> The net price for this school is calculated using this
              school's <b>average</b> institutional aid. Finiverse does not yet
              have enough data for this school to calculate how much
              institutional aid you could receive based on your SAI.
            </p>
          )}
        </>
      ) : (
        <div className="empty-container">
          <p className="empty-state-text">
            You have not yet calculated your SAI.
          </p>
          <Button
            className="calculate-button"
            buttonText={"Calculate"}
            handleOnClick={() => showOverlay("EDIT_SAI")}
            bgColor={theme.colors.primary}
            borderColor={theme.colors.primary}
            textColor={theme.colors.neutrals.white}
            height={"40px"}
          />
        </div>
      )}
      {(selectedTab === "0" || showNetPriceResults) && (
        <Button
          className="add-school-button"
          buttonText={"+ Add School"}
          handleOnClick={() => showOverlay("ADD_SCHOOL")}
          borderColor={theme.colors.secondary}
          bgColor={theme.colors.neutrals.white}
          textColor={theme.colors.secondary}
        />
      )}
      <div className="footer">
        <Button
          className={
            selectedTab === "1" ? `visible-button back-button` : `hidden-button`
          }
          buttonText={
            window.innerWidth < mobileWidth ? "Back" : "Annual Sticker Price"
          }
          handleOnClick={() => setSelectedTab("0")}
          buttonLeftIcon={leftArrow}
          bgColor={theme.colors.neutrals.white}
          borderColor={theme.colors.primary}
          textColor={theme.colors.primary}
        />
        {selectedTab === "0" && (
          <Button
            className="visible-button"
            buttonText={
              window.innerWidth < mobileWidth ? "Next" : "Annual net price"
            }
            handleOnClick={() => {
              setSelectedTab("1");
              const tabsToScrollTo = document.querySelectorAll(".tabs")[0];
              if (!showNetPriceResults) {
                if (tabsToScrollTo) {
                  tabsToScrollTo.scrollIntoView();
                }
              }
            }}
            buttonRightIcon={rightArrow}
            bgColor={theme.colors.neutrals.white}
            borderColor={theme.colors.primary}
            textColor={theme.colors.primary}
          />
        )}
        {selectedTab === "1" && showNetPriceResults && results.length > 0 && (
          <Button
            className="visible-button"
            buttonText={
              window.innerWidth < mobileWidth ? "Next" : "Student Loans"
            }
            handleOnClick={() => {
              navigate("/financing-options");
            }}
            buttonRightIcon={rightArrow}
            bgColor={theme.colors.neutrals.white}
            borderColor={theme.colors.primary}
            textColor={theme.colors.primary}
          />
        )}
      </div>
    </Style>
  );
};

export default Results;
