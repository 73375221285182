import { FC, useEffect } from "react";
import styled from "styled-components";
import theme from "../../theme";
import { useSelector } from "react-redux";
import { RootState } from "../../state";
import { LoanStepLogic } from "./step-logic";
import { GraphComponent } from "./GraphComponent";
import { LoanTrackerComponent } from "./LoanTrackerComponent";

interface LoanAccumulationSectionProps {}

const Style = styled.div`
  min-width: 100%;

  .red {
    color: ${theme.colors.graphColors.redOrange} !important;
  }

  .orange-text {
    color: ${theme.colors.secondary};
  }

  .blue-text {
    color: ${theme.colors.graphColors.indigo};
  }

  .purple-text {
    color: ${theme.colors.graphColors.eggplantPurple};
  }

  .green-text {
    color: ${theme.colors.graphColors.mediumGreen};
  }

  .graph {
    min-width: 100%;
    height: 100px;
  }

  .heading {
    line-height: 1.5;
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    width: 100%;
    .student-loan-graph {
      width: 90%;
    }

    .outlined-explanation-section {
      padding: 10px;
      border-radius: 6px;
      border: 1px solid ${theme.colors.neutrals.gray};
    }
  }

  .rates-section {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    @media (max-width: ${theme.breakpoints.xsmall}px) {
      flex-direction: column;
      gap: 5px;
    }
  }
`;

const LoanAccumulationSection: FC<LoanAccumulationSectionProps> = () => {
  const { currentYear, loanAccumulationStepIndex } = useSelector(
    (state: RootState) => state.financingOptions,
  );

  useEffect(() => {}, [loanAccumulationStepIndex, currentYear]);

  return (
    <Style>
      <h2 className="heading">
        How much would I have to pay in student loans in total?
      </h2>
      {/* TODO: add college year dropdown later */}
      {/* <p>
        To figure out how much you will owe in student loans upon graduating,
        choose the number of years you plan to be in college.
      </p> */}
      <GraphComponent />

      {loanAccumulationStepIndex > 2 && <LoanTrackerComponent />}

      {LoanStepLogic(loanAccumulationStepIndex)}
    </Style>
  );
};

export default LoanAccumulationSection;
