import { FC } from "react";
import { useSelector } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { RootState } from "../state";
import styled from "styled-components";
import theme from "../theme";
import { moneyFormat } from "./utils";
import TextCallout from "../components/TextCallout";

const Style = styled.div``;
interface IDRPlanProps {
  results1: any;
}
const selectSingleSimulationState = (state: RootState) =>
  state.singleSimulation;
const IDRPlanSimulationTab: FC<IDRPlanProps> = ({ results1 }) => {
  const { results } = useSelector(selectSingleSimulationState);
  const mobileWidth = theme.breakpoints.xsmall;
  const totalEarningsOverTime = results.summaryStats.totalEarningsOverTime.map(
    ({ x, y }) => {
      return { x: x * 4, y: y };
    },
  );
  // const totalExpensesOverTime = results.summaryStats.totalExpensesOverTime.map(
  //   ({ x, y }) => {
  //     return { x: x * 4, y: y };
  //   },
  // );
  const totalIDRExpensesOverTime =
    results.summaryStats.totalIDRExpensesOverTime.map(({ x, y }) => {
      return { x: x * 4, y: y };
    });
  const milestonesOverTime = results.summaryStats.milestonesOverTime.map(
    ({ x, milestone }) => {
      return { x: x * 4, milestone: milestone };
    },
  );
  return (
    <Style>
      <TextCallout
        text={
          <>
            <p>
              If you expect to have a low income after graduation, you may want
              to consider an <b>income-driven repayment plan(IDR)</b> instead.
            </p>
            <p>
              In an income-driven repayment plan, the amount you pay each month
              is based on how much money you make. Learn more about IDR in this{" "}
              <a
                href="https://studentloanapp.freshdesk.com/support/solutions/articles/151000096777-what-is-the-idr-plan-"
                style={{
                  textDecorationLine: "underline",
                  color: theme.colors.neutrals.black,
                }}
              >
                this help desk article.
              </a>
            </p>
          </>
        }
      />

      <div className="graph">
        <ResponsiveLine
          data={[
            {
              id: "income",
              color: theme.colors.graphColors.turquoise,
              label: "Income",
              data: totalEarningsOverTime,
            },
            {
              id: "expenses",
              label: "Student Loan Expenses",
              color: theme.colors.graphColors.redOrange,
              data: totalIDRExpensesOverTime,
            },
          ]}
          margin={
            window.innerWidth < mobileWidth
              ? { top: 60, right: 20, bottom: 50, left: 50 }
              : { top: 60, right: 20, bottom: 50, left: 70 }
          }
          colors={[
            theme.colors.graphColors.turquoise,
            theme.colors.graphColors.redOrange,
          ]}
          isInteractive={true}
          useMesh={true}
          enableCrosshair={true}
          enableArea={true}
          // enableSlices="x"
          pointSize={window.innerWidth < mobileWidth ? 3 : 10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={window.innerWidth < mobileWidth ? 1 : 2}
          pointBorderColor={{ from: "serieColor" }}
          axisTop={null}
          axisRight={null}
          axisLeft={
            window.innerWidth < mobileWidth
              ? {
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                }
              : {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                }
          }
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Years after college",
            legendOffset: 36,
            legendPosition: "middle",
            tickValues: [0, 5, 10, 15],
          }}
          xFormat={" >-,"}
          theme={{
            fontSize: 16,
            fontFamily: "Karla",
            legends: { text: { fontSize: 14 } },
            axis: {
              legend: {
                text: {
                  fontSize: 14,
                },
              },
            },
          }}
          legends={[
            {
              anchor: "top-left",
              direction: "row",
              justify: false,
              // translateX: 180,
              translateY: -40,
              itemWidth: 150,
              itemHeight: 20,
              itemsSpacing: -50,
              symbolSize: 16,
              itemDirection: "left-to-right",
              toggleSerie: true,
            },
          ]}
          tooltip={(point) => {
            // console.log("point");
            // console.log(point);
            const seriesName = point.point.serieId.toString();

            const correspondingMilestoneDatum = milestonesOverTime.filter(
              (milestone) => {
                return milestone.x === point.point.data.x;
              },
            );
            // return <></>;

            return (
              <div className="tooltip-container">
                <b>Year {point.point.data.xFormatted}</b>
                <pre style={{ fontFamily: "Karla" }}>
                  {correspondingMilestoneDatum[0].milestone}
                </pre>
                <div>
                  <span className="gray">
                    Annual{" "}
                    {seriesName.charAt(0).toUpperCase() + seriesName.slice(1)}:{" "}
                  </span>
                  <span
                    className={
                      seriesName === "income"
                        ? "income-color"
                        : "expenses-color"
                    }
                  >
                    {`$${moneyFormat(Number(point.point.data.yFormatted))}`}
                  </span>
                </div>
              </div>
            );
          }}
        />
      </div>
    </Style>
  );
};

export default IDRPlanSimulationTab;
