import styled from "styled-components";
import theme from "../theme";
import { useSelector } from "react-redux";
import { RootState } from "../state";
import {
  convertAndFormatHistogramData,
  moneyFormat,
} from "../simulations/utils";
import {
  FormattedHistogramDataPerMetric,
  MultipleSimulationResult,
} from "../types";
import Histogram from "../components/graphs/Histogram";
import { metricMap } from "../constants";
import { FC } from "react";

const Style = styled.div`
  .outline-item {
    border: 2px ${theme.colors.secondary} solid;
    text-align: left;
    margin: auto;
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 18px;
  }
  .risk-outline {
    width: 100%;
  }
  .graph-container {
    width: 100%;
    height: 300px;
  }
  .metric-section {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  hr {
    border: 1px solid ${theme.colors.secondary};
  }
`;

interface RiskSimSummaryProps {
  selectedCollegeId: string;
}

const RiskSimSummary: FC<RiskSimSummaryProps> = ({ selectedCollegeId }) => {
  const { results } = useSelector(
    (state: RootState) => state.multipleSimulation,
  );
  let formatter = Intl.NumberFormat("en", { notation: "compact" });

  const { collegeFinancialSources, selectedJob } = useSelector(
    (state: RootState) => state.financingOptions,
  );
  const loanAmount = collegeFinancialSources
    ? collegeFinancialSources.find(
        (college) => college.collegeId === selectedCollegeId,
      )?.loanAmount
    : 0;

  let graphDataByMetric = {} as {
    [metric: string]: FormattedHistogramDataPerMetric;
  };

  Object.keys(results[0]).forEach((metric) => {
    const selectedMetricObj = Object.entries(metricMap).find(
      ([, metricInfo]) => metricInfo.key === metric,
    );

    const metricData = results.map(
      (result) => result[metric as keyof MultipleSimulationResult],
    );

    const formattedData = convertAndFormatHistogramData(
      metricData,
      results.length,
    );

    const mode = Math.max(...formattedData.map((datum) => datum.likelihood));

    const modeBins = formattedData.filter((datum) => datum.likelihood === mode);

    graphDataByMetric = {
      ...graphDataByMetric,
      [metric]: {
        humanReadableTitle: selectedMetricObj
          ? selectedMetricObj[0]
          : "Total interest paid",
        formattedData: formattedData,
        mode: mode,
        modeBins: modeBins,
      },
    };
  });

  return (
    <Style>
      <p className="risk-summary">
        To see how financial risks could affect you later in life, let’s
        randomly simulate different life paths that could happen during the{" "}
        <b>first 20 years of your life after college.</b>
      </p>
      <p className="risk-summary">
        To help you approximate what the likelihood of different outcomes looks
        like, we ran 1,000 simulations using the following inputs:
      </p>
      <div className="risk-outline">
        <div className="outline-item">
          Job: <b>{selectedJob ? selectedJob?.jobTitle : "None"}</b>
        </div>
        <div className="outline-item">
          Starting Salary:{" "}
          <b>
            $
            {selectedJob
              ? moneyFormat(Number(selectedJob?.avgStartingSalary))
              : "0"}
          </b>
        </div>
        <div className="outline-item">
          Loan Amount: <b>${loanAmount ? moneyFormat(loanAmount) : "0"}</b>
        </div>
      </div>
      {Object.entries(graphDataByMetric).map(([metricName, metricData], i) => {
        return (
          <div className="metric-section">
            <p className="summary-card-title">
              {metricData.humanReadableTitle} after 20 years:
            </p>
            <div className="graph-container">
              <Histogram
                data={metricData.formattedData}
                colors={metricMap[metricData.humanReadableTitle].barColor}
                xAxisKey="amount"
                yAxisKeys={["likelihood"]}
                showYAxis={true}
                xAxisLabel=""
                yAxisLabel="Likelihood"
                showLegend={false}
                showDollarSign={
                  metricMap[metricData.humanReadableTitle].showDollarSign
                }
              />
            </div>
            <p className="risk-summary">
              In {(metricData.mode * 1000).toFixed(0)} of 1,000 simulations (
              {(metricData.mode * 100).toFixed(0)}%), you{" "}
              {metricMap[metricData.humanReadableTitle].action}{" "}
              {metricData.modeBins.map((bin, i) => {
                return (
                  <b>
                    {metricMap[metricData.humanReadableTitle].showDollarSign &&
                      "$"}
                    {bin && bin.bin.x0 && bin.bin.x1
                      ? `${formatter.format(bin.bin.x0)} - ${formatter.format(
                          bin.bin.x1,
                        )}`
                      : "0"}
                    {metricMap[metricData.humanReadableTitle].showYears &&
                      " year(s)"}
                    {i !== metricData.modeBins.length - 1 && " or "}
                  </b>
                );
              })}
              <b>.</b>
            </p>
            {i !== Object.entries(graphDataByMetric).length - 1 && <hr />}
          </div>
        );
      })}
    </Style>
  );
};

export default RiskSimSummary;
