import styled from "styled-components";
import PennLogo from "../assets/images/Penn Logo.png";
import theme from "../theme";

const Style = styled.div`
  background: ${theme.colors.pennBlue};
  height: 60px;
  padding: 60px 80px 80px 80px;
  color: ${theme.colors.neutrals.white};

  .footer-logo {
    height: 70%;
  }

  a {
    color: ${theme.colors.neutrals.white};
    text-decoration: none;
  }

  .footer-text {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints.small}px) {
    height: 220px;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding: 80px 40px;

    .footer-logo {
      height: 20%;
    }

    .footer-text {
      font-size: 16px;
      text-align: center;
    }
  }
`;

// TODO: update this with the correct Penn logo
const Footer = () => {
  return (
    <Style>
      <img className="footer-logo" src={PennLogo} alt="Penn logo" />
      <p className="footer-text">
        <a href="https://apps.wharton.upenn.edu/authentication?service=wordpress&returnURL=https://stevenscenter.wharton.upenn.edu/cms-login">
          ©
        </a>
        2024 <a href="https://www.wharton.upenn.edu/">The Wharton School</a>,{" "}
        <a href="https://www.upenn.edu/">The University of Pennsylvania</a> |{" "}
        <a href="https://stevenscenter.wharton.upenn.edu/">
          Stevens Center for Innovation in Finance
        </a>{" "}
        |{" "}
        <a href="https://www.wharton.upenn.edu/privacy-policy/">
          Privacy Policy
        </a>{" "}
        |{" "}
        <a href="https://accessibility.web-resources.upenn.edu/get-help">
          Report Accessibility Issues and Get Help
        </a>
      </p>
    </Style>
  );
};

export default Footer;
