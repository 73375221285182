import { combineReducers, configureStore } from "@reduxjs/toolkit";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import "./index.css";
import App from "./App";
import collegeCostsReducer from "./college-costs/college-costs-slice";
import financingOptionsReducer from "./student-loans/financing-options-slice";
import singleSimulationReducer from "./simulations/single-simulation-slice";
import multipleSimulationReducer from "./simulations/multiple-simulation-slice";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import navigationReducer from "./home/navigation-slice";

const store = configureStore({
  reducer: combineReducers({
    collegeCosts: collegeCostsReducer,
    financingOptions: financingOptionsReducer,
    singleSimulation: singleSimulationReducer,
    multipleSimulation: multipleSimulationReducer,
    navigation: navigationReducer,
  }),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <ThemeProvider theme={theme}>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
