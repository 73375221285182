import { FC, ReactNode } from "react";
import styled from "styled-components";
import theme from "../theme";
import MUIToolTip from "../components/MUIToolTip";

interface LoanFigureComponentProps {
  className?: string;
  label: string;
  value: string | ReactNode;
  footnote?: string | ReactNode;
  textColor: string;
  tooltipText?: string;
  paddingBottom?: number;
  paddingTop?: number;
  width?: number;
  id?: string;
}

interface StyleProps {
  textColor: string;
  paddingBottom?: number;
  paddingTop?: number;
  width?: number;
}

const Style = styled.div<StyleProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: ${(props) => props.width}%;
  gap: 4px;
  padding-top: ${(props) => props.paddingTop}px;
  padding-bottom: ${(props) => props.paddingBottom}px;

  .label {
    color: ${(props) => props.textColor || theme.colors.primary};
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    @media (max-width: ${theme.breakpoints.small}px) {
      width: 130px;
    }
  }

  .value {
    color: ${(props) => props.textColor || theme.colors.primary};
    font-size: 2rem;
    font-weight: 700;
    line-height: 110%;
  }

  .footnote {
    color: ${(props) => props.textColor || theme.colors.primary};
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  @media (max-width: ${theme.breakpoints.small}px) {
    .label {
      width: 100%;
      font-size: 16px;
    }
    .value {
      width: 100%;
      font-size: 24px;
    }
  }
`;

export const LoanFigureComponent: FC<LoanFigureComponentProps> = ({
  className = "",
  label,
  value,
  footnote,
  textColor,
  tooltipText,
  paddingBottom = 0,
  paddingTop = 0,
  width = 100,
  id,
}) => {
  return (
    <Style
      className={className}
      textColor={textColor}
      paddingBottom={paddingBottom}
      width={width}
      paddingTop={paddingTop}
    >
      <div className="label" id={id}>
        {label}
        {tooltipText && (
          <MUIToolTip text={tooltipText} anchorName="loan-figure-anchor" />
        )}
      </div>
      <div className="value">{value}</div>
      {footnote && <div className="footnote">{footnote}</div>}
    </Style>
  );
};
