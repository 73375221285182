import styled from "styled-components";
import HorizontalCard from "../components/HorizontalCard";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const NewsContent = [
  {
    title: "Finiverse is now on Instagram!",
    date: "August 14, 2024",
    description:
      "The Finiverse Instagram account @finiversewharton is officially live, bringing you the latest updates about the project as well as exclusive content about our student team.",
    link: "https://www.instagram.com/finiversewharton/",
    buttonText: "FOLLOW US",
  },
  {
    title:
      "'Finiverse': App created by Philly students aims to help navigate student loans",
    date: "April 25, 2024",
    sourceName: "FOX 29 Philadelphia",
    description:
      "David Musto, Robert King and Alhaji Bah join Good Day Philadelphia to discuss the 'Finiverse' app, which aims to help students navigate the confusing student loans process.",
    link: "https://www.fox29.com/video/1446464",
    buttonText: "WATCH VIDEO",
  },
  {
    title:
      "Philly high schoolers develop easy app to help predict the true cost of college",
    date: "April 23, 2024",
    sourceName: "Technical.ly",
    description:
      "Finiverse, a project out of Wharton’s Stevens Center, supplements what students said was a lack of resources in Philadelphia public schools.",
    link: "https://technical.ly/software-development/financial-aid-app-philadelphia-students/",
    buttonText: "READ MORE",
  },
];

const News = () => {
  return (
    <Style>
      {NewsContent.map(
        ({ title, date, description, sourceName, link, buttonText }) => {
          return (
            <HorizontalCard
              title={title}
              sourceName={sourceName}
              description={description}
              date={date}
              link={link}
              buttonText={buttonText}
            />
          );
        },
      )}
    </Style>
  );
};

export default News;
