import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import styled from "styled-components";
import theme from "../theme";

const Style = styled.section`
  width: 100%;

  .timeline-item {
    padding-bottom: 24px;
    font-size: 22px;
  }

  .timeline-date {
    margin-right: 24px;
  }

  .timeline-description {
    margin-left: 24px;
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .timeline-item {
      font-size: 15px;
    }

    .timeline-date {
      margin-right: 0;
    }

    .timeline-description {
      margin-left: 0;
    }
  }
`;

export const ourStoryContent = [
  {
    date: "Spring 2021",
    description:
      "The idea for Finiverse is born. Faculty Director of the Stevens Center for Innovation in Finance Professor David Musto generates an idea for an app that helps students navigate their college finances and understand relevant financial risks.",
  },
  {
    date: "Summer 2021",
    description:
      "The Stevens Center conducts focus groups of high school students to validate the idea for Finiverse and refine proof of concept. Gillian Bazelon joins the Stevens Center to help conduct these focus groups and bring high school students into the project.",
  },
  {
    date: "January 2022",
    description:
      "Levra Labs and Wharton MBA student Akwasi Brefo join the team to lead engineering and product development.",
  },
  {
    date: "Spring 2022",
    description:
      "Design and initial development of the MVP of the project begins.",
  },
  {
    date: "Summer 2022",
    description:
      "The Stevens Center offers paid summer internship to 20 high school students to learn product development, coding, and research skills.",
  },
  {
    date: "Fall 2022",
    description:
      "Wharton MBA student Emily Lu joins the project as a project manager. The Stevens Center kicks off a year-round internship program with over 20 high school and college undergrad students. Initial risk simulation is developed.",
  },
  {
    date: "Spring 2023",
    description:
      "The Stevens Center internship program continues. 20 high school interns work to develop a Beta version of Finiverse.",
  },
  {
    date: "June 2023",
    description: "Soft launch of Beta version of Finiverse.",
  },
  {
    date: "Fall 2023",
    description:
      "The Finiverse team visits local Philadelphia high schools to gather feedback on the beta version and conduct live demos.",
  },
  {
    date: "April 2024",
    description: (
      <>
        <b>Official launch of Finiverse.</b> Finiverse is featured on{" "}
        <a href="https://www.fox29.com/video/1446464">Fox 29 News</a> and on the
        front page of{" "}
        <a href="https://technical.ly/software-development/financial-aid-app-philadelphia-students/">
          Technical.ly
        </a>
        .
      </>
    ),
  },
];

export default function OurStory() {
  return (
    <Style>
      <Timeline
        position="right"
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.5,
          },
        }}
      >
        {ourStoryContent.map((timelineItem, id) => {
          return (
            <TimelineItem>
              <TimelineOppositeContent
                color="black"
                fontWeight="bold"
                fontFamily={"Karla"}
                className="timeline-item timeline-date"
              >
                {timelineItem.date}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot sx={{ background: theme.colors.primary }} />
                {id !== ourStoryContent.length - 1 && (
                  <TimelineConnector
                    sx={{ background: theme.colors.lightIndigo }}
                  />
                )}
              </TimelineSeparator>
              <TimelineContent
                fontFamily={"Karla"}
                className="timeline-item timeline-description"
              >
                {timelineItem.description}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Style>
  );
}
