import styled from "styled-components";
import TextCallout from "../components/TextCallout";
import SimulationInputs from "./SimulationInputs";
import Number1 from "../assets/icons/number-1.svg";
import Number2 from "../assets/icons/number-2.svg";
import Number3 from "../assets/icons/number-3.svg";
import theme from "../theme";
import EstimateNote from "../components/EstimateNote";

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .written-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    line-height: 140%;
    text-align: left;

    .red-text {
      color: #9e2b25;
      font-weight: 700;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
    margin-bottom: 24px;

    .list-item {
      display: flex;
      align-items: center;
    }
  }

  .number-circle {
    margin-right: 8px;
  }

  .instruction-text {
    margin-top: 24px;
  }

  @media (max-width: ${theme.breakpoints.xsmall}px) {
    .instruction-text {
      font-size: 18px;
      line-height: 1.3em;
    }
    .written-content > p {
      font-size: 18px;
      line-height: 1.3em;
    }
    .list > .list-item > span {
      font-size: 16px;
    }
    .list > .list-item > img {
      width: 30px;
      margin-right: 14px;
    }
  }
`;

const SimulationIntro = () => {
  return (
    <Style>
      <div className="written-content">
        <p>
          Repaying your student loan is a long-term financial obligation that
          comes with several financial risks, such as:
          <div className="list">
            <div className="list-item">
              <img src={Number1} className="number-circle" alt="" />{" "}
              <span>
                <b>Unemployment:</b> not being able to find a job after
                graduation
              </span>
            </div>
            <div className="list-item">
              <img src={Number2} className="number-circle" alt="" />{" "}
              <span>
                <b>Low income:</b> not earning enough money to make your loan
                payments
              </span>
            </div>
            <div className="list-item">
              <img src={Number3} className="number-circle" alt="" />{" "}
              <span>
                <b>Dropping out:</b> not completing your college degree, which
                can negatively impact your ability to find a job
              </span>
            </div>
          </div>
          <TextCallout
            text={
              <>
                It’s important to understand these financial risks and to make a
                plan for repaying your student loans.
              </>
            }
          />
          <EstimateNote />
          <div className="instruction-text">
            To see how financial risks could affect you later in life, let’s
            randomly simulate one potential version of your life path for the
            <b> first 10-20 years of your life </b>after college.
          </div>
        </p>
      </div>
      <SimulationInputs showOverlay={false} />
    </Style>
  );
};

export default SimulationIntro;
