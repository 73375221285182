import { RadioGroup, FormControl } from "@mui/material";
import DefaultRadio from "./DefaultRadio";
import styled from "styled-components";

interface RadioButtonGroupProps {
  label?: string;
  buttonOptions: string[];
  fieldValue?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Style = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  .row-label {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  buttonOptions,
  fieldValue = "",
  handleChange,
}) => {
  return (
    <Style>
      {label && <div className="row-label">{label}</div>}
      <FormControl>
        <RadioGroup
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={fieldValue}
          onChange={handleChange}
        >
          {buttonOptions.map((value) => {
            return <DefaultRadio value={value} label={value} />;
          })}
        </RadioGroup>
      </FormControl>
    </Style>
  );
};

export default RadioButtonGroup;
