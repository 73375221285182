import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface DropdownProps {
  fieldTitle: string;
  fieldValue: string;
  dropdownOptions: string[];
  handleChange: (value: any) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  fieldTitle,
  fieldValue,
  dropdownOptions,
  handleChange,
}) => {
  return (
    <FormControl fullWidth sx={{ marginBottom: "1em" }}>
      <InputLabel id="select-filled-label">{fieldTitle}</InputLabel>
      <Select
        labelId="select-filled-label"
        id="simple-select"
        value={fieldValue}
        label={fieldTitle}
        onChange={(e: SelectChangeEvent) => {
          handleChange(e.target.value);
        }}
        sx={{ background: "#ffffff", textAlign: "left" }}
      >
        {dropdownOptions.map((option) => (
          <MenuItem value={option}>{option}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
